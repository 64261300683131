import React, { useEffect } from 'react'
import { MenuTree } from '../../../modelos/menu'
import { Estado } from '../../../modelos/types/type-react'
import { gestorReqs } from '../../../servicos/gestor'
import { AcessoRapidoData } from './sessao-header'

interface IHeaderContext {
    menus: MenuTree[] | undefined,
    setMenus: React.Dispatch<React.SetStateAction<MenuTree[] | undefined>>,
    estado: Estado,
    setEstado: React.Dispatch<React.SetStateAction<Estado>>,
    acessoRapidoData: AcessoRapidoData | undefined,
    setAcessoRapidoData: React.Dispatch<React.SetStateAction<AcessoRapidoData | undefined>>
}

interface ISubmenuContext {
  submenuAberto: boolean,
  setSubmenuAberto: React.Dispatch<React.SetStateAction<boolean>>
}

interface IProps {
  children: JSX.Element
}

const HeaderContext = React.createContext<IHeaderContext>({estado: {carregando: false, erro: false}} as IHeaderContext)
const SubmenuContext = React.createContext<ISubmenuContext>({submenuAberto: false} as ISubmenuContext)

export const HeaderProvider: React.FC<IProps> = ({children}) => {

  const [menus, setMenus] = React.useState<MenuTree[] | undefined>(undefined)
  const [estado, setEstado] = React.useState<Estado>({ carregando: true, erro: false })
  const [acessoRapidoData, setAcessoRapidoData] = React.useState<AcessoRapidoData>()
  const [submenuAberto, setSubmenuAberto] = React.useState<boolean>(false)

  const [context, setContext] = React.useState<IHeaderContext>({
    menus, setMenus,
    estado, setEstado,
    acessoRapidoData, setAcessoRapidoData,
  })

  React.useEffect(() => {
    const getMenus = gestorReqs.getMenus()
    const getInformacoes = gestorReqs.getInformacoes()

    Promise.all([getMenus, getInformacoes]).then(responses => {
      const [resMenus, resInformacoes] = responses

      if (!resMenus.error && resInformacoes) {
        setMenus(resMenus.dados)

        var dataAcessoRapido = {
          telefone: resInformacoes?.phones?.[0]?.phone ?? '',
          email: resInformacoes?.email ?? '',
          facebook: resInformacoes?.social_media?.filter(rs => rs.id == 9)?.[0]?.link ?? '',
          youtube: resInformacoes?.social_media?.filter(rs => rs.id == 10)?.[0]?.link ?? '',
          instagram: resInformacoes?.social_media?.filter(rs => rs.id == 8)?.[0]?.link ?? '',
          portal: 'https://realmetais.accionsistemas.com.br/pSeven/login.seam?cid=1',
          logo: resInformacoes?.image ?? ''
        }

        setAcessoRapidoData(dataAcessoRapido)

        setTimeout(() => setEstado({ carregando: false, erro: false }), .500)
      } else setEstado({ carregando: false, erro: true })

    })
  }, [])

  useEffect(() => {
    setContext({...context, menus, estado, acessoRapidoData})
  }, [menus, estado, acessoRapidoData])

  return <HeaderContext.Provider value={context}>{children}</HeaderContext.Provider>
}

export const SubmenuProvider: React.FC<IProps> = ({children}) => {

  const [submenuAberto, setSubmenuAberto] = React.useState<boolean>(false)

  const [context, setContext] = React.useState<ISubmenuContext>({
    submenuAberto,
    setSubmenuAberto
  })

  React.useEffect(() => setContext({...context, submenuAberto}), [submenuAberto])

  return <SubmenuContext.Provider value={context}>{children}</SubmenuContext.Provider>
}

export const useHeaderContext = () => {
  return React.useContext(HeaderContext)
}

export const useSubmenuContext = () => {
  return React.useContext(SubmenuContext)
}
