export type PLIForm = {
  total: number
  pagesPerIndex: number
  fixeds: number
  middle: number
  breakpoint: number
  index: number
}

export type PLIResponse = [
  empty: boolean, 
  prevIndexes: number[],
  mainIndex: number[],
  posIndex:number[],
  target: number,
  showPrevSeparator: boolean,
  showPosSeparator: boolean
]
export function pageLayoutIndexes({total, pagesPerIndex = 1, fixeds = 1, middle = 3, breakpoint = middle, index = 0}: PLIForm): PLIResponse {
  var empty = total <= 0
  var prevIndexes: number[] = []
  var mainIndex: number[] = []
  var posIndex:number[] = []
  var target: number

  var length = ((total / pagesPerIndex) - Math.floor(total / pagesPerIndex)) === 0 ? Math.floor(total / pagesPerIndex) : Math.floor( total / pagesPerIndex) + 1

  var indexes: number[] = new Array(length).fill(null).map((v, i) => i)

  if(index > indexes.length)   index = indexes.length - 1
  if(index < 0) index = 0
  

  if( ((index >= breakpoint) || (index >= middle)) && index <= Math.floor(middle /2) ){
      var start = (index - Math.floor(middle / 2)) < 0 ? 0 : (index - Math.floor(middle / 2))
      var end = (index + Math.floor(middle / 2)) > indexes.length ? indexes.length : (index + Math.floor(middle / 2) + 1)

      var posStart = (indexes.length - fixeds) <  end ? end : (indexes.length - fixeds) 

      return [
          empty,
          [],
          indexes.slice(start, end),
          indexes.slice(posStart),
          index,
          false,
          indexes.slice(posStart).length > 0
      ]
  }
  
  var start = ((index - Math.floor(middle / 2)) < 0 || index < breakpoint ) ? 0 : (index - Math.floor(middle / 2)) - ( index === (indexes.length - 1) ? 1 : 0)
  var end = (index + Math.floor(middle / 2)) > indexes.length ? indexes.length : (index + Math.floor(middle / 2) + ((index === 0)? 2 : 1) )

  var preEnd = fixeds >= start ? start : fixeds

  var posStart = (indexes.length - fixeds) <  end ? end : (indexes.length - fixeds)


  return [
      empty,
      indexes.slice(0, preEnd),
      indexes.slice(start, end),
      indexes.slice(posStart),
      index,
      indexes.slice(0, preEnd).length > 0,
      indexes.slice(posStart).length > 0
  ]

}



/*
var [empty, pre, mid, pos, target, isPrev, isPos] = pageLayoutIndexes({
  total: 100,
  pagesPerIndex: 10,
  fixeds: 2,
  middle: 3,
  breakpoint: 3,
  index: page,
})
*/