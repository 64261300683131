import React, { useEffect, useState } from 'react'
import './lgpd.scss'

export const Lgpd = () => {

    const [ render, setRender ] = useState(true);

    
    const confirmar = () => {
        window.localStorage.setItem("lgpd", "1");
        setRender(false);
    }

    useEffect(() => {
        if(window.localStorage.getItem('lgpd') == "1") {
            setRender(false);
        }
    },[])

    if(!render) {
        return <div></div>;
    }

    return <div className="container-fluid" id="body-cookie-politica-privacidade" style={{display:"block"}}>
                <div className="row-lgpd">
                    <div className="descricao-politica-privacidade">
                        <span>Este site usa cookies para garantir que você obtenha a melhor experiência em nossa página.<br/>Ao utilizá-lo, você concorda com esse uso de cookies.
                            {/* <br/> Conheça nossa <a className="politica" href="https://www.cvale.com.br/site/web-files/pdfs/7.%20POL%C3%8DTICA%20DE%20PRIVACIDADE%20Site%20C.Vale%20nova%20-%20V.02.pdf" title="política de privacidade" target="_blank">política de privacidade</a> */}
                        </span>
                    </div>
                    <div className="btn-politica-privacidade">
                        <a onClick={() => confirmar()} href="javascript:void(0);" className="check-cookie" title="Aceito">Aceito</a>
                    </div>
                </div>
    </div>;

}