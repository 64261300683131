import './sessao-subcategorias.scss'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { COLORS } from '../../../themes/colors';
import 'react-loading-skeleton/dist/skeleton.css'
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ProdutoCard, ProdutoCardTipo } from '../../produto-card/produto-card';
import { gestorReqs } from '../../../servicos/gestor';
import { CategoriaProdutoServicoType } from '../../../modelos/types/type-categoria-produto';
import { toUrlAmigavelConverter } from '../../../utilidades/funcoes-auxiliares'
import { toTop } from '../../../utilidades/manipuladores-html';

const TAMANHO_PAGINA = 24

type Estado = {
  carregando: boolean,
  erro: boolean
}

type SubcategoriaResumo = {
  id: number,
  titulo: string,
  link: string
}

export const SessaoSubcategorias = () => {
  const { id } = useParams()

  useEffect(() => {
    toTop()
    
    gestorReqs.getCategoriasProdutoServico().then(response => {
      if(!response.error && response.dados){
        var subcategorias: CategoriaProdutoServicoType[] = response.dados.filter(cps => cps.category_id === Number(id))

        var arr = subcategorias.map(subcat => {
          return {
            id: subcat.id,
            titulo: subcat.title,
            link: `/produtos/${subcat.id}/${toUrlAmigavelConverter(subcat.title)}`
          }
        })

        setSubcategorias(arr)

      }else setEstado({carregando: false, erro: true})
    })

    setTimeout(() => setEstado({carregando: false, erro: false}), 1500)
  }, [])

  const [estado, setEstado] = React.useState<Estado>({ carregando: true, erro: false })
  const [subcategorias, setSubcategorias] = React.useState<SubcategoriaResumo[]>([])

  return (
    <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>

        <Container id='sessaoSubcategorias'>
          {estado.carregando ? <SkeletonSubcategoriaSessao qtd={3}/>:
            subcategorias.map((subcategoria, idx) => (
              <SubcategoriasSecao id={subcategoria.id} baseUrl={subcategoria.link} titulo={subcategoria.titulo} vejaMaisLink={subcategoria.link} key={idx}/>
            ))
          }
        </Container>
    </SkeletonTheme>
  )
}



const SubcategoriasSecao = ({ id, baseUrl, titulo, vejaMaisLink }: { id: number, baseUrl: string, titulo: string, vejaMaisLink: string }) => {
  const [mobile, setMobile] = React.useState<boolean>(true)

  useEffect(() => {
    const handleResize = () => setMobile(!(window.innerWidth > 991.98))

    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])

  useEffect(() => {
    // Consultar produtos da categorias Aqui
    gestorReqs.getProdutosServicosPorCategoria(1, TAMANHO_PAGINA, Number(id)).then(response => {
      if(!response.error && response.dados){
        var arr: ProdutoCardTipo[] = response.dados.resultados.map(ps => {
          return {
            id: ps.id,
            titulo: ps.title,
            descricao: ps.description,
            imagem: ps.imagensArray?.[0]?.src ?? '',
            link: `${baseUrl}/${ps.id}/${toUrlAmigavelConverter(ps.title)}`
          }
        })

        setProdutos(arr)
        setEstado({carregando: false, erro: false})

      }else setEstado({carregando: false, erro: true})
    })

  }, [id])

  const [estado, setEstado] = React.useState<Estado>({ carregando: true, erro: false })
  const [produtos, setProdutos] = React.useState<ProdutoCardTipo[]>([])

  const splitarProdutos = (qtdPorLinha: number, produtos: ProdutoCardTipo[]): ProdutoCardTipo[][] => {

    var produtosSplitados: ProdutoCardTipo[][] = []
    var coluna = 0

    while (1) {
      var array: ProdutoCardTipo[] = []

      for (let cont of Array(qtdPorLinha).fill(null)) {
        if (coluna >= produtos.length) break
        array.push(produtos[coluna])
        coluna += 1
      }

      produtosSplitados.push(array)

      if (coluna >= produtos.length) break
    }

    return produtosSplitados
  }

  return (
    estado.erro ? <></> : estado.carregando ? <SkeletonSubcategoriaSessao qtd={1}/> :
      <Row className='rowSubcategoria'>
        <Col xs={12} className='subcategoriaTitulo'><span>{titulo}</span></Col>
        <Col xs={12} lg={9} className='colSubcategoria'>
          <Carousel indicators={true} controls={!mobile && produtos.length > 3} className='produtosCardCarousel'>
            {
              splitarProdutos((mobile ? 1 : 3), produtos).filter((v, index) => mobile ? index < 6 : true).map((produtos, idx) => (
                <Carousel.Item key={idx}>
                  <Row>
                    {
                      produtos.map((produto, i) => (

                        <Col xs={12} lg={4} key={i}>
                          <ProdutoCard dados={produto} />
                        </Col>
                      ))
                    }
                  </Row>
                </Carousel.Item>
              ))
            }
          </Carousel>
        </Col>
        <Col xs={12} lg={3} className='colVejaMais'>
          <VejaMais link={vejaMaisLink} carregando={false} nome={titulo}/>
        </Col>
      </Row>
  )
}

const VejaMais = ({ link, carregando, nome }: { link: string, carregando: boolean, nome: string }) => (
  carregando ? <Skeleton className='skeletonVejaMais' height={'100%'} width={'100%'} /> :
    <Link to={link} className='linkVejaMais'>
      <div className='vejaMais'>
        <div className='caixa'>
          <span>VEJA MAIS {nome}</span>

          <div className='linkBotao'>
            <i className="bi bi-arrow-right" style={{ color: "white", fontSize: '1.6rem' }} ></i>
          </div>

        </div>
      </div>
    </Link>

)

const SkeletonSubcategoria = () => (
  <Row className='skeletonSubcategoria'>
    <Col className='subcategoria' xs={4}>
      <Skeleton height={'100%'} width={'100%'} />
    </Col>
    <Col className='subcategoria' xs={4}>
      <Skeleton height={'100%'} width={'100%'} />
    </Col>
    <Col className='subcategoria' xs={4}>
      <Skeleton height={'100%'} width={'100%'} />
    </Col>
  </Row>
)

const SkeletonSubcategoriaSessao = ({qtd}: {qtd: number}) => {

  return (
    <Container className='skeletonSubcategoriaSessao'>
        {
          Array(qtd).fill(null).map((subcategoria, idx) => (
            <Row key={idx} className='rowSkeletonSubcategoriaSessao'>
              <Col xs={12} className='titulo'><Skeleton width={'200px'} height={'40px'}/></Col>
              <Col xs={12} lg={9} className='colSubcategoria'>
                <SkeletonSubcategoria />
              </Col>
              <Col xs={12} lg={3} className='colVejaMais'>
                <VejaMais link={``} carregando={true} nome={''}/>
              </Col>
            </Row>
          ))
        }
      </Container>
  )
}