import { IDeserializable } from './deserializable';

export interface IPageble<T> {
  pagina: number;
  pageSize: number;
  totalResultados: number;
  totalPaginas: number;

  resultados: Array<T>;
}

export class Pageble<T> implements IDeserializable, IPageble<T> {
  pagina: number = 0;
  pageSize: number = 20;
  totalResultados: number = 0;
  totalPaginas: number = 0;

  resultados: Array<T> = new Array();

  deserialize(input: IPageble<T>): this {
    Object.assign(this, input);
    return this;
  }
} 