import { useEffect, useState } from "react";
import { Carousel, Modal } from "react-bootstrap";
import './imagem-viewer-zoom.scss'

export const ImagemViewerZoom = ({imagens, indicators = true, controls = true}: {imagens: string[], indicators?: boolean, controls?:boolean}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    imagens.length && setShow(true)
  }, [imagens])

  return (
      <Modal className="sobreNosModal" show={show} fullscreen={true} onHide={() => setShow(false)}>
        <i className={`bi bi-x-lg closeModal`} onClick={() => setShow(false)} />
        <Modal.Body>
                <Carousel controls={controls && imagens.length > 1} indicators={indicators && imagens.length > 1}>
                  {
                    imagens.map((img, i) => (
                      <Carousel.Item key={i}>
                        <div className="img">
                          <img
                            className="d-block w-100"
                            src={img}
                            alt="First slide"
                          />
                        </div>
                      </Carousel.Item>
                    ))
                  }
                </Carousel>
        </Modal.Body>
      </Modal>
  )
}