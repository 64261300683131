import axios from "axios";
import { Blog } from "../modelos/blog";
import { SectionData } from "../modelos/section";
import { Image } from "../modelos/image";
import { Pageble } from "../modelos/pageable";
import { convertDateToUTC } from "../utilidades/date";
import { Category } from "../modelos/category";
import Informacoes from "../modelos/informacoes";
import { MenuRec, MenuTree } from "../modelos/menu";
import {
  GestorResponse,
  GestorResponseDeserializable,
} from "../modelos/gestor-response";
import { SubscribeRespostaEnum } from "../modelos/subscribe";
import CategoriasModel from "../modelos/categorias";
import { ProdutoServicoType } from "../modelos/types/type-produto";
import { Paginacao } from "../modelos/paginacao";
import { CategoriaProdutoServicoType } from "../modelos/types/type-categoria-produto";
import ProdutosModel from "../modelos/model-produtos";
import { FiltroCaracteristica } from "../modelos/types/type-filtro-caracteristica";
import { BannerType } from "../modelos/types/type-banner";
import { BannerInternoType } from "../modelos/types/type-banner-interno";
import { ContatoStatus, ContatoType } from "../modelos/types/types-contato";
import { QuemSomosType } from "../modelos/types/type-quem-somos";
import { ArquivoType } from "../modelos/types/type-arquivo";
import { idCaracteristicsFilterType } from "../modelos/types/type-id-caracteristics-filter";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/site`,
  headers: {
    company: `${process.env.REACT_APP_COMPANY_ID}`,
    Accept: "application/json",
  },
});

const changeHighlightToFirst = (images: Image[]): Image[] => {
  images?.forEach((image, index) => {
    if (image?.showFirst) {
      var aux = images[0];
      images[0] = images[index];
      images[index] = aux;
    }
  });

  return images;
};

let cacheMapGetSections = new Map();
let mapPs = new Map();
const getSections = async (section: string): Promise<SectionData[]> => {

  if(cacheMapGetSections.has(section)) {
    return cacheMapGetSections.get(section);
  }

  try {

    let promise = mapPs.has(section) ? mapPs.get(section) : api.get<any>(`/secao/${section}`);
    mapPs.set(section, promise);

    const { data, status } = await mapPs.get(section);
    var sections: SectionData[] = [];

    data.data.forEach((item: any) =>
      sections.push(new SectionData().deserialize(item))
    );

    cacheMapGetSections.set(section, sections);

    return sections;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("error on getSection - message: ", error.message);
    } else {
      console.warn("unexpected error on getSection ", error);
    }

    return [];
  }
};

const _getSections = async (
  section: string
): Promise<GestorResponse<SectionData[]>> => {
  try {
    const { data, status } = await api.get<any>(`/secao/${section}`);
    var sections: SectionData[] = [];

    data.data.forEach((item: any) =>
      sections.push(new SectionData().deserialize(item))
    );

    return new GestorResponse(false, sections);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("error on getSection - message: ", error.message);
    } else {
      console.warn("unexpected error on getSection ", error);
    }

    return new GestorResponse(true);
  }
};

const getBlogsPageble = async (
  page: number,
  pageSize: number
): Promise<Pageble<Blog>> => {
  try {
    const { data, status } = await api.get<any>(
      `/blog?page=${page}&limit=${pageSize}`
    );

    var blogs: Blog[] = [];

    data.data.forEach((e: any) => {
      var imagens: Image[] = e.imagensArray.map((img: any) => {
        var image: Image = new Image().deserialize({
          src: img.src,
          showFirst: img.showFirst,
        });

        return image;
      });

      var blog = new Blog().deserialize({
        id: e.id,
        title: e.title,
        description: e.description,
        author: e.author,
        company: e.company,
        createdAt: convertDateToUTC(new Date(e.createdAt)),
        updatedAt: convertDateToUTC(new Date(e.updatedAt)),
        start_date: convertDateToUTC(new Date(e.start_date)),
        end_date: convertDateToUTC(new Date(e.end_date)),
        category: e.category,
        tags: e?.tags?.split(" "),
        disabled: e.disabled,
        imagens: changeHighlightToFirst(imagens),
      });

      blogs.push(blog);
    });

    return new Pageble<Blog>().deserialize({
      pagina: page,
      pageSize: pageSize,
      resultados: blogs,
      totalResultados: data.total,
      totalPaginas: Math.floor(data.total / pageSize) + 1,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("error on getBlogsByPage - message: ", error.message);
      return new Pageble<Blog>();
    } else {
      console.warn("unexpected error on getBlogsByPage: ", error);
      return new Pageble<Blog>();
    }
  }
};

const getBlogById = async (blogId: number): Promise<Blog | undefined> => {
  try {
    const { data, status } = await api.get<any>(`/blog/${blogId}`);

    var imagens: Image[] = data.imagensArray.map((img: any) => {
      var image: Image = new Image().deserialize({
        src: img.src,
        showFirst: img.showFirst,
      });

      return image;
    });

    return new Blog().deserialize({
      id: data.id,
      title: data.title,
      description: data.description,
      author: data.author,
      company: data.company,
      createdAt: convertDateToUTC(new Date(data.createdAt)),
      updatedAt: convertDateToUTC(new Date(data.updatedAt)),
      start_date: convertDateToUTC(new Date(data.start_date)),
      end_date: convertDateToUTC(new Date(data.end_date)),
      category: data.category,
      tags: data.tags?.split(" "),
      disabled: data.disabled,
      imagens: changeHighlightToFirst(imagens),
      arquivos: data.arquivosArray as ArquivoType[],
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("error on getBlogById - message: ", error.message);
      return undefined;
    } else {
      console.warn("unexpected error on getBlogById: ", error);
      return undefined;
    }
  }
};

let cacheGetCategoriasBlog: any = null;
let ps5: any = null;
const getCategories = async (): Promise<Category[]> => {

  if(cacheGetCategoriasBlog) {
    return cacheGetCategoriasBlog;
  }
  try {

    ps5 = ps5 || api.get<any>(`/blog/categorias`);
    const { data } = await ps5;

    var categories: Category[] = [];

    data.forEach((e: any) => {
      var category = new Category().deserialize({
        id: e?.id,
        name: e?.name,
        quantity: e?.quantity,
      });

      categories.push(category);
    });

    var total = categories.reduce(
      (ac, va) => (va.quantity ? va.quantity + ac : ac),
      0
    );

    var categoryAll = new Category().deserialize({
      id: -1,
      name: "Todos",
      quantity: total,
    });

    categories.push(categoryAll);

    cacheGetCategoriasBlog = categories;

    return categories;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("error on getCategories - message: ", error.message);
      return [];
    } else {
      console.warn("unexpected error on getCategories: ", error);
      return [];
    }
  }
};

const searchBlogsByCategory = async (
  page: number,
  pageSize: number,
  categoryId: number
): Promise<Pageble<Blog>> => {
  try {
    var data: any;
    if (categoryId === -1) {
      var { data: dataAxios } = await api.get<any>(
        `/blog?page=${page}&limit=${pageSize}`
      );
      data = dataAxios;
    } else {
      var { data: dataAxios } = await api.get<any>(
        `/blog?page=${page}&limit=${pageSize}&category=${categoryId}`
      );
      data = dataAxios;
    }

    var blogs: Blog[] = [];

    data.data.forEach((e: any) => {
      var imagens: Image[] = e.imagensArray.map((img: any) => {
        var image: Image = new Image().deserialize({
          src: img.src,
          showFirst: img.showFirst,
        });

        return image;
      });

      var blog = new Blog().deserialize({
        id: e?.id,
        title: e?.title,
        description: e?.description,
        author: e?.author,
        company: e?.company,
        createdAt: convertDateToUTC(new Date(e.createdAt)),
        updatedAt: convertDateToUTC(new Date(e.updatedAt)),
        start_date: convertDateToUTC(new Date(e.start_date)),
        end_date: convertDateToUTC(new Date(e.end_date)),
        category: e?.category,
        tags: e?.tags?.split(" "),
        disabled: e?.disabled,
        imagens: changeHighlightToFirst(imagens),
      });

      blogs.push(blog);
    });

    return new Pageble<Blog>().deserialize({
      pagina: page,
      pageSize: pageSize,
      resultados: blogs,
      totalResultados: data.total,
      totalPaginas: Math.floor(data.total / pageSize) + 1,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("error on searchBlogsByCategory - message: ", error.message);
      return new Pageble<Blog>();
    } else {
      console.warn("unexpected error on searchBlogsByCategory: ", error);
      return new Pageble<Blog>();
    }
  }
};

let cacheGetinformacoes: any = null;
let ps1: any = null;
const getInformacoes = async (): Promise<Informacoes> => {
  if(cacheGetinformacoes) {
    return cacheGetinformacoes;
  }

  const retorno = new Informacoes();
  try {

    ps1 = ps1 || api.get("/informacoes");
    const { data, status } = (await ps1) as any;

    const ret = retorno.deserialize(data);
    cacheGetinformacoes = ret;
    return ret;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("error on informacoes - message: ", error.message);
    } else {
      console.warn("unexpected error on informacoes: ", error);
    }
    return retorno;
  }
};

let cacheGetMenus: any = null;
let ps2: any = null;
const getMenus = async (): Promise<GestorResponse<MenuTree[]>> => {
  if(cacheGetMenus) {
    return cacheGetMenus;
  }
  try {
    ps2 = ps2 || api.get<any>(`/menus`)
    const { data, status } = await ps2;

    var mapper = new Map<number, MenuTree>();
    var array: MenuTree[] = [];

    data.forEach((item: any) => {
      var menu = new MenuRec().deserialize({
        id: item.id,
        name: item.name,
        description: item.description,
        html: item.html,
        isLink: item.isLink,
        image: item.image,
        menuId: item.menu_id,
        order: item.order,
      });

      var tree = {
        menu: menu,
        childrens: [],
      };

      if (tree.menu.id) {
        mapper.set(tree.menu.id, tree);
        if (menu.menuId) {
          var father = mapper.get(menu.menuId);
          if (father) {
            menu.html = menu.html!;
            father.childrens.push(tree);
          }
        } else {
          array.push(tree);
        }
      }
    });

    const sortByName = (a: MenuTree, b: MenuTree) => a.menu.name?.localeCompare(b?.menu?.name ?? '') ?? 0
    const sortByOrder = (a: MenuTree, b: MenuTree) => (a?.menu?.order || 0) - (b?.menu?.order || 0)

    array = array.sort(sortByOrder);
    array.forEach((menu) => {
      menu.childrens = menu.childrens.sort(sortByName);
      menu.childrens.forEach((children2) => {
        children2.childrens = children2.childrens.sort(sortByName);
      });
    });

    const ret = new GestorResponse(false, array);
    cacheGetMenus = ret;
    return ret;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("error on getBlogById - message: ", error.message);
      return new GestorResponse(true);
    } else {
      console.warn("unexpected error on getBlogById: ", error);
      return new GestorResponse(true);
    }
  }
};

const postEmailNewsletter = async (
  email: string
): Promise<SubscribeRespostaEnum> => {
  try {
    const { data, status } = await api.post(`/subscribe`, { email: email });

    return SubscribeRespostaEnum.OK;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === SubscribeRespostaEnum.EMAIL_EXISTENTE)
        return SubscribeRespostaEnum.EMAIL_EXISTENTE;

      if (error.response?.status === SubscribeRespostaEnum.VAZIO)
        return SubscribeRespostaEnum.VAZIO;

      console.warn("error on postEmailNewsletter - message: ", error.message);

      return SubscribeRespostaEnum.ERRO_INDEFINIDO;
    } else {
      console.warn("unexpected error on postEmailNewsletter: ", error);
      return SubscribeRespostaEnum.ERRO_INDEFINIDO;
    }
  }
};


let cacheGetCategorias: any = null;
let ps6: any = null;
const getCategorias = async (): Promise<CategoriasModel> => {

  if(cacheGetCategorias) {
    return cacheGetCategorias;
  }

  const retorno = new CategoriasModel();

  try {
    ps6 = ps6 || api.get(`/categorias`);
    const { data, status } = await ps6;
    const ret = retorno.deserialize({ dados: data });
    cacheGetCategorias = ret;
    return ret;
  } catch (error) {
    console.warn(
      axios.isAxiosError(error)
        ? "erro on categorias - message: " + error.message
        : " unexpected erro on categorias: " + error
    );

    return retorno.deserialize({ error: true });
  }
};

const getProdutoById = async (
  id: number
): Promise<GestorResponse<ProdutoServicoType>> => {
  try {
    const { data } = await api.get<any>(`/produtos_servicos/${id}`);

    const produtoServico: ProdutoServicoType = data as ProdutoServicoType;

    return new GestorResponse<ProdutoServicoType>(false, produtoServico);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn(
        "error on getProdutosPorCategoria - message: ",
        error.message
      );
      return new GestorResponse<ProdutoServicoType>(true);
    } else {
      console.warn("unexpected error on getProdutosPorCategoria: ", error);
      return new GestorResponse<ProdutoServicoType>(true);
    }
  }
};

const getCategoriasProdutoServico = async (): Promise<
  GestorResponse<CategoriaProdutoServicoType[]>
> => {
  try {
    const { data, status } = await api.get<any>(`/categorias`);

    const sortByTitle = (a: CategoriaProdutoServicoType, b: CategoriaProdutoServicoType) => a.title.localeCompare(b.title)

    var resultados: CategoriaProdutoServicoType[] =
      data as CategoriaProdutoServicoType[];
    
    if(resultados.length)
      resultados = resultados.sort(sortByTitle)

    return new GestorResponse<CategoriaProdutoServicoType[]>(false, resultados);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn(
        "error on getCategoriasProdutoServico - message: ",
        error.message
      );
      return new GestorResponse(true);
    } else {
      console.warn("unexpected error on getCategoriasProdutoServico: ", error);
      return new GestorResponse(true);
    }
  }
};

const getProdutosServicosPorCategoria = async (
  pagina: number,
  limite: number,
  categoriaId: number
): Promise<GestorResponse<Paginacao<ProdutoServicoType>>> => {
  try {
    const { data } = await api.get<any>(
      `/produtos_servicos?page=${pagina}&limit=${limite}&category=${categoriaId}`
    );

    var resultados: ProdutoServicoType[] = data.data as ProdutoServicoType[];

    var paginacao = new Paginacao<ProdutoServicoType>(
      pagina,
      limite,
      data.total,
      Math.ceil(data.total / limite),
      resultados
    );

    return new GestorResponse<Paginacao<ProdutoServicoType>>(false, paginacao);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn(
        "error on getProdutosPorCategoria - message: ",
        error.message
      );
      return new GestorResponse(true);
    } else {
      console.warn("unexpected error on getProdutosPorCategoria: ", error);
      return new GestorResponse(true);
    }
  }
};

const getProdutos = async (
  pagina: string,
  limit: string
): Promise<ProdutosModel> => {
  const retorno = new ProdutosModel();

  try {
    const { data } = await api.get<any>(
      `/categorias?page=${pagina}$limit=${limit}`
    );

    return retorno.deserialize({ dados: data });
  } catch (error) {
    console.log(
      axios.isAxiosError(error)
        ? "Error on getProdutos - message: " + error.message
        : "Unexpected error on getProdutos: " + error
    );

    return retorno.deserialize({ error: true });
  }
};

const getFiltrosCaracteristicas = async (
  category?: number,
  search?: string
): Promise<GestorResponse<FiltroCaracteristica[]>> => {
  try {
    const { data } = await api.get(
      `/produtos_servicos_caracteristicas?category=${
        category ? category : ""
      }&search=${search ? search : ""}`
    );

    return new GestorResponse<FiltroCaracteristica[]>(
      false,
      data as FiltroCaracteristica[]
    );
  } catch (error) {
    console.warn(
      axios.isAxiosError(error)
        ? `Erro em getFiltrosCaracteristicas - mensagem: ${error.message}`
        : `Erro não esperado em getFiltrosCaracteristicas - mensagem: ${error}`
    );

    return new GestorResponse(false);
  }
};

const getProdutosFiltradosPorCaracteristicas = async (
  pagina: number,
  limite: number,
  categoriaId: number,
  caracristicasIds: number[],
  search?: string
): Promise<GestorResponse<Paginacao<ProdutoServicoType>>> => {
  try {
    var queries = "";

    for (let c of caracristicasIds) {
      queries += `&characteristics=${c}`;
    }

    if (categoriaId) queries += `&category=${categoriaId}`;

    if (search) queries += `&search=${search}`;

    const { data } = await api.get<any>(
      `/produtos_servicos?page=${pagina}&limit=${limite}` + queries
    );

    var resultados: ProdutoServicoType[] = data.data as ProdutoServicoType[];

    var paginacao = new Paginacao<ProdutoServicoType>(
      pagina,
      limite,
      data.total,
      Math.ceil(data.total / limite),
      resultados
    );

    return new GestorResponse<Paginacao<ProdutoServicoType>>(false, paginacao);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn(
        "error on getProdutosPorCategoriaFiltrados - message: ",
        error.message
      );
      return new GestorResponse(true);
    } else {
      console.warn(
        "unexpected error on getProdutosPorCategoriaPFiltrados: ",
        error
      );
      return new GestorResponse(true);
    }
  }
};

const getCategoriaPorId = async (
  id: number
): Promise<GestorResponse<CategoriaProdutoServicoType>> => {
  const response = await getCategoriasProdutoServico();
  const categoryWithSameId = response.dados?.filter(
    (cat) => Number(cat.id) === id
  );

  if (!response.error && categoryWithSameId?.length)
    return new GestorResponse(false, categoryWithSameId[0]);
  return new GestorResponse(true);
};

let cacheGetBanner: any = null;
let ps3: any = null;
const getBanner = async (): Promise<
  GestorResponseDeserializable<Array<BannerType>>
> => {
  if(cacheGetBanner) {
    return cacheGetBanner;
  }
  const retorno = new GestorResponseDeserializable<Array<BannerType>>();

  try {
    ps3 = ps3 || api.get("banners")
    const { data } = await ps3;

    return retorno.deserialize({ dados: data });
  } catch (error) {
    console.log(
      axios.isAxiosError(error)
        ? `Error on banner - message : ${error.message}`
        : `Unexpected error on banner: ${error}`
    );

    const ret = retorno.deserialize({ error: true });
    cacheGetBanner = ret;
    return ret;
  }
};

const getBannerInterno = async (
  page: string
): Promise<GestorResponseDeserializable<Array<BannerInternoType>>> => {
  const retorno = new GestorResponseDeserializable<Array<BannerInternoType>>();

  try {
    const { data } = await api.get(`bannerInterno?page=${page}`);

    return retorno.deserialize({ dados: data });
  } catch (error) {
    console.log(
      axios.isAxiosError(error)
        ? `Error on banner interno ${page} - message : ${error.message}`
        : `Unexpected error on banner interno ${page}: ${error}`
    );

    return retorno.deserialize({ error: true });
  }
};

const postContato = async (body: ContatoType): Promise<ContatoStatus> => {
  try {
    const { data, status } = (await api.post(`/contato`, body)) as any;

    return ContatoStatus.OK;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("Error on Contato - message: ", error.message);
      return ContatoStatus.ERROR;
    }

    console.warn("Unexpect error on Contato: ", error);
    return ContatoStatus.ERROR;
  }
};

let cacheGetQuemSomos: any = null;
let ps4: any = null;
const getQuemSomos = async (): Promise<
  GestorResponseDeserializable<QuemSomosType>
> => {

  if(cacheGetQuemSomos) {
    return cacheGetQuemSomos;
  }
  const retorno = new GestorResponseDeserializable<QuemSomosType>();
  try {

    ps4 = ps4 || api.get(`quem_somos`);
    const { data } = await ps4;

    return retorno.deserialize({ dados: data });
  } catch (error) {
    console.log(
      axios.isAxiosError(error)
        ? `Error on Quem Somos - message : ${error.message}`
        : `Unexpected error on Quem Somos : ${error}`
    );

    const ret = retorno.deserialize({ error: true });
    cacheGetQuemSomos = ret;
    return ret;
  }
};

const getFiltrosPossiveis = async (
  categoria: number,
  idCaracteristicas?: Array<number>
): Promise<GestorResponseDeserializable<Array<idCaracteristicsFilterType>>> => {
  const retorno = new GestorResponseDeserializable<
    Array<idCaracteristicsFilterType>
  >();

  try {

    const { data } = (await api.get(
      `id-caracteristics-filter?category=${categoria}`,
      {
        headers: {
          idcaracteristicas: idCaracteristicas?.toString() ?? "",
        },
      }
    )) as { data: unknown };

    return retorno.deserialize({ dados: data });
  } catch (error) {
    console.log(
      axios.isAxiosError(error)
        ? `Error on Quem Somos - message : ${error.message}`
        : `Unexpected error on Quem Somos : ${error}`
    );

    return retorno.deserialize({ error: true });
  }
};

export const gestorReqs = {
  getSections,
  _getSections,
  getBlogsPageble,
  getBlogById,
  getCategories,
  searchBlogsByCategory,
  getInformacoes,
  getMenus,
  postEmailNewsletter,
  getCategorias,
  getProdutoById,
  getCategoriasProdutoServico,
  getProdutosServicosPorCategoria,
  getProdutos,
  getFiltrosCaracteristicas,
  getProdutosFiltradosPorCaracteristicas,
  getCategoriaPorId,
  getBanner,
  getBannerInterno,
  postContato,
  getQuemSomos,
  getFiltrosPossiveis,
};
