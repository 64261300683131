import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import './menu-tabela.scss'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { COLORS } from '../../themes/colors'

export type MenuTabelaType = {
	id: number,
	qtd: number,
	name: string
}

export const MenuTabela = ({ queryParam, dados, boldSelected, changeCategoria }: { queryParam: string, dados: MenuTabelaType[] | undefined, boldSelected?: boolean, changeCategoria ?: () => void}) => {

	const search = useLocation().search
	const categoriaId = new URLSearchParams(search).get('category');

	const selectedClass = (id: string | number): string => {
		if ((id == categoriaId || (id == -1 && categoriaId === null)) && boldSelected)
			return 'selected'
		else return ''
	}
	return (
		<section id='menuTabela'>
			<SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
				<Container>
					<Row className='tabela'>
						<Col xs={12} lg={11} className='tabelaCol'>
							{dados &&
								<>
									<div className='titulo'><span>Categorias</span></div>
									{dados.map((dado, i) =>
										<Link key={`MTR${i}`} className={`categoria ${selectedClass(dado.id)}`} to={dado.id == -1 ? '/blog' : `/blog?category=${dado.id}`}
										onClick={changeCategoria}>
											<div className='nome'><span>{dado.name}</span></div>
											<div className='qtd' ><span>{dado.qtd}</span></div>
										</Link>
									)}
								</>
								|| <SkeletonTabela />
							}
						</Col>
					</Row>
				</Container>
			</SkeletonTheme>
		</section>
	)
}




// ----------------- skeletons ---------------

const SkeletonTabela = () => {
	return (
		<div className='skeletonTabelaContainer'>
			<Skeleton width={'100%'} height={'100%'} />
		</div>
	)
}