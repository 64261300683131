import { ListagemCategorias } from '../../listagem-categorias/listagem-categorias'
import './sessao-listagem-categorias.scss'

export type SessaoListagemCategoriasVariant = 'normal' | 'resumo'

export const SessaoListagemCategorias = ({variant}: {variant: SessaoListagemCategoriasVariant}) => {
    return (
        <section id='sessaoListagemCategorias'>
            <ListagemCategorias variant={variant}/>
        </section>
    )
}