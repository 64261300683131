import React from 'react'
import { useEffect } from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import { useScreenContext } from '../../../contexts/screen.context'
import { gestorReqs } from '../../../servicos/gestor'
import { toUrlAmigavelConverter } from '../../../utilidades/funcoes-auxiliares'
import { DetalhesProduto } from '../../detalhes-produto/detalhes-produto'
import { ProdutoCard, ProdutoCardTipo } from '../../produto-card/produto-card'
import { SessaoListagemProdutos } from '../sessao-listagem-produtos/sessao-listagem-produtos'
import { SessaoProdutosRelacionados } from '../sessao=produtos-relacionados/sessao-produtos-relacionados'
import './sessao-produto.scss'



export const SessaoProduto = () => {
  return (
    <section id='sessaoProduto'>
      <DetalhesProduto />
      <SessaoProdutosRelacionados />

      {/* [ Relacionados ] */}
    </section>
  )
}
