import { IDeserializable } from "./deserializable"

export class GestorResponse<T> {

  constructor(error: boolean, dados?: T){
    this.dados = dados
    this.error = error
  }

  dados?: T
  error: boolean

}

export class GestorResponseDeserializable<T> implements IDeserializable {

  dados?: T
  error?: boolean;

  deserialize(input: any): this {
    Object.assign(this,input);
    return this;
  }

}