import React from 'react';
import { preProcessFile } from 'typescript';
import { pageLayoutIndexes  } from './pageLayoutIndexes';
import './seletores.scss'

type InputSeletoresType = {
  total: number,
  itemsPerPage: number,
  middle: number, 
  fixeds: number, 
  selected: number,
  changePage: (index: number) => void,
}

type SeletoresControlsType = {
  empty: boolean;
  pre: number[];
  mid: number[];
  pos: number[];
  target: number
  isPrev: boolean;
  isPos: boolean;
}

export const Seletores = ({total = 0, itemsPerPage = 0, middle = 3, fixeds = 1, selected = 1, changePage}:InputSeletoresType) => {
  const [control, setControl] = React.useState<SeletoresControlsType>({
    empty: true,
    pre: [],
    mid: [],
    pos: [],
    target: 1,
    isPrev: false,
    isPos: false
  })

  React.useEffect(() => {
    const [empty, pre, mid, pos, target, isPrev, isPos] = pageLayoutIndexes({
      total,
      pagesPerIndex: itemsPerPage,
      fixeds,
      middle, 
      breakpoint: middle -1,
      index: selected - 1
    })

    setControl({
      empty,
      pre,
      mid,
      pos,
      target,
      isPrev,
      isPos
    })
  }, [total, itemsPerPage, middle, fixeds, selected])

  const changePageHandler = (page: number) => {
    changePage(page)
  }

  const selectedClass = (i: number): string => {
    return i === (selected - 1) ? 'selected' : ''
  }

  return (
    <section id='seletores'>
      { control.pre.map((v, i) => 
        <div className={`ibtn pre ${selectedClass(v)}`} onClick={() => changePageHandler(v + 1)} key={`pre${i}`}>{v+1}</div>
      )}

      {control.isPrev && <div className='separator'></div>}

      { control.mid.map((v, i) => 
        <div className={`ibtn mid ${selectedClass(v)}`} onClick={() => changePageHandler(v + 1)} key={`mid${i}`}>{v+1}</div>
      )}

      {control.isPos && <div className='separator'></div>}

      { control.pos.map((v, i) => 
        <div className={`ibtn pos ${selectedClass(v)}`} onClick={() => changePageHandler(v + 1)} key={`pos${i}`}>{v+1}</div>
      )}
    </section>
  )
}