import React, {useEffect, useState} from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { gestorReqs } from '../../../servicos/gestor'
import { MenuListagemProdutos } from '../../menu-listagem-produtos/menu-listagem-produtos'
import { ProdutoCard, ProdutoCardTipo } from '../../produto-card/produto-card'
import './sessao-listagem-produtos-pesquisa.scss'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { COLORS } from '../../../themes/colors'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { toUrlAmigavelConverter } from '../../../utilidades/funcoes-auxiliares'
import { scrolldiv, toTop } from '../../../utilidades/manipuladores-html'

export type SessaoListagemProdutos = 'normal' | 'relacionados'

type ControlePagina = {
  pagina: number,
  total: number,
  itensPorPagina: number,
}

type Estado = {
  carregando: boolean,
  erro: boolean,
}

type SubcategoriaType = {
  nome: string,
  links: ({
    nome: string,
    href: string
  })[]
}

export const SessaoListagemProdutosPesquisa = ({ variant }: { variant: SessaoListagemProdutos }) => {

  const TAMANHO_PAGINA = 24
  
  const [estado, setEstado] = React.useState<Estado>({carregando: true, erro: false})
  const [page, setPage] = React.useState<number>(1)
  const [mostrarMais, setMostrarMais] = React.useState<boolean>(false)
  const [caracteristicas, setCaracteristicas] = React.useState<number[]>([])
  const [naoEncontrado, setNaoEncontrado] = React.useState<boolean>(false)
  const [cards, setCards] = React.useState<ProdutoCardTipo[]>([])
  const [params] = useSearchParams();

  const handleVejaMais = () => {
    buscarProdutos(page + 1, caracteristicas, true)
    setPage(page + 1)
    setMostrarMais(false)
  }

  const f = (caracteristicasIds: number[], page: number) => {
    setCards([])
    scrolldiv('ancora-listagem-produtos-por-categoria', window.innerWidth > 992 ? -150 : -150);
    setCaracteristicas(caracteristicasIds)
    setPage(1)
    buscarProdutos(1, caracteristicasIds)
  }

  const buscarProdutos = (pagina: number, caracteristicasArr: number[], append: boolean = false) => {
    setNaoEncontrado(false)
    if(!append) setEstado({carregando: true, erro: false})

    gestorReqs.getCategorias().then(responseCat => {

      if(!responseCat.erro && responseCat.dados){
        gestorReqs.getProdutosFiltradosPorCaracteristicas(pagina, TAMANHO_PAGINA, 0, caracteristicasArr, params.get('search') ?? '').then(response => {
          var mapCategorias = new Map<string, number>()

          responseCat.dados!.map(cat => {
            if(cat.title && cat.id){
              mapCategorias.set(cat.title, cat.id)
            }
          })

          if(!response.error && response.dados){
            var c: ProdutoCardTipo[] = response.dados.resultados.map(p => {
              return {
                id: p.id,
                titulo: p.title,
                imagem: p.imagensArray?.[0]?.src,
                descricao: p.description,
                link: `/produtos/${mapCategorias.get(p.category)}/${toUrlAmigavelConverter(p.category)}/${p.id}/${toUrlAmigavelConverter(p.title)}`,
              }
            })
            
            if(response.dados.totalPaginas <= pagina) setMostrarMais(false)
            else setMostrarMais(true)
    
            if(!c.length){
              setCards([])
              setNaoEncontrado(true)
            }
            else if(append)
              setCards([...cards, ...c])
            else
              setCards(c)
    
            setEstado({carregando: false, erro: false})
          }else setEstado({carregando: false, erro: true})
        })
      }else setEstado({carregando: false, erro: true})
    })
  }


  useEffect(() => {
    toTop()
    setPage(1)
    scrolldiv('ancora-listagem-produtos-por-categoria', window.innerWidth > 992 ? -150 : -150);
    buscarProdutos(1, [])
  }, [, params])

  if(estado.erro) return <></>
  else return (
    <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
        <Container id='sessaoListagemProdutos'>
          <Row className='produtosListagem'>
            
            <Col xs={12} lg={3}>
              <MenuListagemProdutos filtrar={f} search={params.get('search') ?? undefined}/>
            </Col>

            <Col xs={12} lg={9} className='listagem'>

              <div id='ancora-listagem-produtos-por-categoria'></div>

              { naoEncontrado && 
                <div className='naoEncontrado'>
                    <span>Nenhum produto encontrado</span>
                </div>
              }

              <ListagemProdutosCards cards={cards} carregando={estado.carregando} />
              
              { mostrarMais && !estado.carregando && 
                <div className='vejaMais'>
                  <div className='botao' onClick={() => handleVejaMais()}>
                    VEJA MAIS
                  </div>
                </div>
            }
            </Col>
          </Row>
        </Container>
    </SkeletonTheme>
  )
}

const ListagemProdutosCards = ({cards, carregando}:{cards: ProdutoCardTipo[], carregando: boolean}) => {

  return carregando ? <SkeletonListagemProdutosCards /> : (
    <Container id='listagemProdutosCards'>
      <Row>
        {
          cards.map((card, idx) => (
            <Col className='cardProduto' xs={12} lg={4} key={idx}>
              <ProdutoCard dados={card}/>
            </Col>
          ))
        }
      </Row>
    </Container>
  )
}

const SkeletonListagemProdutosCards = () => {
  return (
    <Container>
      <Row>
        {
          Array(3).fill(null).map((v, i) => (
            <Col xs={12} lg={4} key={i}>
              <div className='skeletonListagemProdutosCards'>
                <Skeleton width={'100%'} height={'100%'} />
              </div>
            </Col>
          ))
        }
      </Row>
    </Container>
  )
}

const SkeletonBreadcrumbs = () => {
  return (
    <div className='breadcrumbSkeleton'>
      <Skeleton width={'100%'} height={'100%'} />
    </div>
  )
}