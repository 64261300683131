import React from 'react'
import { Banner, ThumbnailBanner } from '../../componentes/banner/banner'
import { SessaoContato } from '../../componentes/sessoes/sessao-contato/sessao-contato'
import { SessaoMaps } from '../../componentes/sessoes/sessao-maps/sessao-maps'
import { SessaoInformacoesContato } from '../../componentes/sessoes/sessao-informacoes-contato/sessao-informacoes-contato'
import { SessaoHeader } from '../../componentes/sessoes/sessao-header/sessao-header'
import './pagina-contato.scss'
import { gestorReqs } from '../../servicos/gestor'
import { useTitle } from '../../utilidades/tittle'
import { toTop } from '../../utilidades/manipuladores-html'

export const PaginaContato = () => {
    const [thumbnailsBanner, setThumbnailsBanner] = React.useState<ThumbnailBanner[]>([])
    
    useTitle("Fale Conosco - Real Metais");

    React.useEffect(() => {
        toTop();
        gestorReqs.getBannerInterno('contato').then(resposta=>{
            setThumbnailsBanner([{
                title: 'Fale Conosco',
                img: resposta.dados?.at(0)?.imagensArray.at(0)?.src??"",
                breadcrumbs: [
                    {
                        name: 'home',
                        url: '/'
                    },{
                        name: 'Fale Conosco',
                        url: '/fale-conosco'
                    }],
            }]);
        });
    }, [])

    return (
        <section id='paginaContato'>
            <SessaoHeader variant='transparent' acessoRapido/>
            <Banner variant='interna' thumbnails={thumbnailsBanner}/>
            <SessaoMaps />
            <SessaoContato />
            <SessaoInformacoesContato />
        </section>
    )
}