import { Carousel, Col, Row } from 'react-bootstrap'
import { isMainThread } from 'worker_threads'
import parse from 'html-react-parser';
import DownloadIcon from '../../assets/imagens/downloadIcon.png';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { COLORS } from '../../themes/colors'

import './detalhes-blog.scss'
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { gestorReqs } from '../../servicos/gestor';
import { Blog } from '../../modelos/blog';
import { toTop } from '../../utilidades/manipuladores-html';
import { useTitle } from '../../utilidades/tittle';
import { FuncoesAuxiliares } from '../../utilidades/funcoes-auxiliares';

export const DetalhesBlog = ({ setCategoriaNome }: { setCategoriaNome: (nome: string | undefined) => void }) => {

  const { id } = useParams()

  const [blog, setBlog] = useState<Blog>()

  const [screen, setScreen] = useState({ width: 0, height: 0 });
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    toTop()
    id && gestorReqs.getBlogById(Number(id)).then(response => setBlog(response))
  }, [id])

  useEffect(() => {
    setCategoriaNome(blog?.category)
  }, [blog])

  useEffect(() => {
    function updateSize() {
      setScreen({ width: window.innerWidth, height: window.innerHeight });
    }

    window.addEventListener('resize', updateSize);

    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  useEffect(() => {
    if (screen.width < 992)
      setIsMobile(true)
    else setIsMobile(false)
  }, [screen])

  const getOnlyNameOfFile = (urlFile: string): string => {
    var arr = urlFile.split('/')
    return arr[arr.length - 1]
  }

  useTitle(blog?.title ?? "Blog");

  const data = new Date(blog?.start_date??"");

  return (
    <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
      <section id='detalhesBlog'>
        <Row>
          <Col xs={12} className='carouselBox'>
            {blog &&
              <Carousel controls={!isMobile && blog?.imagens && (blog?.imagens?.length > 1 ?? false)} indicators={isMobile}>
                {blog.imagens?.map((img, i) =>
                  <Carousel.Item key={i}>
                    <div className='img'>
                      <img src={img.src} alt={"img" + i} />
                    </div>
                  </Carousel.Item>
                )}
              </Carousel>
              || <SkeletonCarouselDetalhesBlog />
            }
          </Col>
          <Col xs={12} className='header'>
            {blog && <div className='categoria'>
              {blog.category} | {FuncoesAuxiliares.dataParaEscrita(`${data.getDate()}/${data.getMonth()}/${data.getFullYear()}`)}
            </div> || <SkeletonCategoriaTagDetalhesBlog />}
            {blog &&
              <div className='titulo'>{blog.title}</div>
              || <SkeletonTituloDetalhesBlog />
            }
          </Col>
          <Col xs={12} className='body'>
            {blog &&
              parse(blog.description ? blog.description : '')
              || <SkeletonTextDetalhesBlog />
            }
          </Col>
        </Row>

        {blog && blog?.arquivos && blog.arquivos.length &&
          <Row className='arquivos'>
            <Col xs={12}>
              <div className='arquivosTitulo'><span>Arquivos Para Baixar</span></div>
            </Col>
            {blog.arquivos.map((arquivo, i) =>
              <Col xs={12} className='arquivo' key={i}>
                <a href={arquivo.src} target="_blank" rel="noopener noreferrer">
                  <div className='downloadIcon'>
                    <img src={DownloadIcon} alt='download-icon' />
                  </div>
                  <span>{getOnlyNameOfFile(arquivo.src)}</span>
                </a>
              </Col>
            )}
          </Row> || <></>
        }
      </section>
    </SkeletonTheme>
  )
}


//    ------------ skeletons --------------

const SkeletonCarouselDetalhesBlog = () => {
  return (
    <div className='skeletonCarouselDetalhesBlog'>
      <Skeleton width={'100%'} height={'100%'} />
    </div>
  )
}

const SkeletonTituloDetalhesBlog = () => {
  return (
    <div className='skeletonTituloDetalhesBlog'>
      <Skeleton width={'100%'} height={'100%'} />
    </div>
  )
}

const SkeletonCategoriaTagDetalhesBlog = () => {
  return (
    <div className='skeletonCategoriaTagDetalhesBlog'>
      <Skeleton width={'100%'} height={'100%'} />
    </div>
  )
}

const SkeletonTextDetalhesBlog = () => {
  return (
    <div className='skeletonCategoriaTagDetalhesBlogContainer'>
      {
        Array(3).fill(null).map((_, i) =>
          <div className='skeletonCategoriaTagDetalhesBlog' key={i}>
            <Skeleton width={'100%'} height={'100%'} />
          </div>
        )}
    </div>

  )
}