import './produto-card.scss'
import { Link } from 'react-router-dom';
import { removerProdutoDosFavoritos, setarProdutoComoFavorito, verificarSeProdutoEhFavorito } from '../../utilidades/manipuladores-local-storage';
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';

export type ProdutoCardTipo = {
  id: number,
  titulo: string,
  imagem: string,
  descricao: string,
  link: string,
  arquivoCadastro?: string
}

export const ProdutoCard = ({ dados }: { dados: ProdutoCardTipo }) => {
  const [ehFavorito, setEhFavorito] = React.useState<boolean>(verificarSeProdutoEhFavorito(dados.id))

  const setarFavorito = (favoritar: boolean) => {
    if (favoritar)
      setarProdutoComoFavorito(dados.id)
    else
      removerProdutoDosFavoritos(dados.id)

    setEhFavorito(verificarSeProdutoEhFavorito(dados.id))
  }

  useEffect(() => {
    setEhFavorito(verificarSeProdutoEhFavorito(dados.id))
  }, [dados])

  return (

    <div className='produtoCard withBorder'>
      {!ehFavorito &&
        <div className='favoritoBotao' onClick={() => setarFavorito(true)}>
          <i className="bi bi-heart favorito" ></i> 
          {/* <Heart className='favorito' /> */}
        </div>}

      {ehFavorito &&
        <div className='favoritoBotao' onClick={() => setarFavorito(false)}>
          {/* <HeartFill className='favorito' /> */}
          <i className="bi bi-heart-fill favorito" onClick={() => setarFavorito(false)}  ></i> 
        </div>}

      <Link to={dados.link} className='produtoCardLink' reloadDocument>
        <div className='imagem'>
          <img src={dados.imagem} alt={`imagem ${dados?.titulo}`} />
        </div>
        <div className='descricaoBox'>
          <div className='descricao'>
            <p>
              {dados.titulo}
            </p>
          </div>

        </div>
      </Link>

      {dados.arquivoCadastro ?
        <div className='containerBaixarFicha'>
          <a href={dados.arquivoCadastro}>
            <Button className="botao" variant="outline-primary">
              BAIXAR FICHA CADASTRAL
            </Button>
          </a>
        </div> : null}

    </div>
  )
}