import { DetalhesBlog } from '../../detalhes-blog/detalhes-blog'
import { MenuTabela, MenuTabelaType } from '../../menu-tabela/menu-tabela'
import { SessaoListagemBlogs } from '../sessao-listagem-blogs/sessao-listagem-blogs'
import './sessao-blog.scss'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { COLORS } from '../../../themes/colors'
import { Carousel, Col, Container, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { gestorReqs } from '../../../servicos/gestor'
import { useParams } from 'react-router-dom'
import { toTop } from '../../../utilidades/manipuladores-html'

export const SessaoBlog = () => {
  const [dadosMenuTabela, setDadosMenuTabela] = useState<MenuTabelaType[]>()
  const [categoriaNome, setCategoriaNome] = useState<string>()
  const [categoriaId, setCategoriaId] = useState<number>()

  const { id } = useParams();

  useEffect(() => {
    toTop()
    gestorReqs.getCategories().then(response => {
      var menuTabelaDados: MenuTabelaType[] = response.map(c => ({...c, qtd: c.quantity} as MenuTabelaType))

      menuTabelaDados.forEach((m, i) => {
         if(m.id === -1){
            var aux = menuTabelaDados[0];
            menuTabelaDados[0] = m;
            menuTabelaDados[i] = aux;
         }
      })

      setDadosMenuTabela(menuTabelaDados)
    })
  }, [])

  useEffect(() => {
    if(categoriaNome){
      gestorReqs.getCategories().then(response => {
        response.forEach( categoria => (categoria.name == categoriaNome) && setCategoriaId(categoria.id))
      })
    }else setCategoriaId(undefined)
  }, [categoriaNome])

  return (
    <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
      <section id="sessaoBlog">
        <div id='ancora-blog-interna-top'></div>
        <Container>
          <Row className='layout'>
            <Col className='internaBox' xs={12} lg={8}>
              <DetalhesBlog setCategoriaNome={setCategoriaNome}/>
            </Col>
            <Col className='tabelaBox' xs={12} lg={4}>
              <MenuTabela queryParam='category' dados={dadosMenuTabela} boldSelected={false}/>
            </Col>
          </Row>
        </Container>
        <SessaoListagemBlogs variant='resumo' categoriaId={categoriaId}/>
      </section>
    </SkeletonTheme>
  )
}
