import { useState, useEffect } from "react";
import { gestorReqs } from "../../../servicos/gestor";
import "./sessao-whatsapp.scss";

type DadosInformacoes = {
  whatsapp: string;
}; 

export const SessaoWhatsapp = () => {
  const [dados, setDados] = useState<DadosInformacoes>();
  useEffect(() => {
    gestorReqs.getInformacoes().then((response) => {
      setDados(response as DadosInformacoes);
    });
  }, []);

  useEffect(() => {});
  return (
    <section id="sessaoWhatsapp">
      <a href={`https://api.whatsapp.com/send?phone=55${dados?.whatsapp.replace(/\D/g, '')}`} className="whatsapp" target="blank" title="WhatsApp">
      <i className="bi bi-whatsapp" style={{ color: "white", fontSize: '2rem' }} ></i>
    </a>
    </section >
  );
};