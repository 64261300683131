import { type } from '@testing-library/user-event/dist/type'
import { Carousel, Col, Container, Row } from 'react-bootstrap'
import './listagem-categorias.scss'
import placeHolder from '../../assets/imagens/placeHolder1.svg'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CategoriaType } from '../../modelos/categorias'
import { gestorReqs } from '../../servicos/gestor'
import { FuncoesAuxiliares } from '../../utilidades/funcoes-auxiliares'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { COLORS } from '../../themes/colors'

export type ListagemCategoriasVariant = 'normal' | 'resumo'

export const ListagemCategorias = ({ variant }: { variant: ListagemCategoriasVariant }) => {

  type ListagemCategoriasType = {
    loading: boolean,
    erroAoCarregar: boolean,
    dados: {
      categorias?: Array<CategoriaType>
    }
  }

  const [estado, setEstado] = useState<ListagemCategoriasType>({
    loading: true,
    erroAoCarregar: false,
    dados: {}
  });

  const [isResume, setIsResume] = React.useState(false)

  React.useEffect(() => setIsResume(variant === 'resumo'), [variant])

  useEffect(() => {
    gestorReqs.getCategorias().then(resposta => {

      if (resposta.erro) {
        estado.erroAoCarregar = true;
        setEstado({ ...estado });
        return;
      }

      estado.dados.categorias = resposta.dados;

      estado.loading = false;
      setEstado({ ...estado });

    });
  }, []);

  return (
    <section id='ListagemCategorias' className={variant}>
      {isResume ?
        <>
          <Container fluid className='resumoBody'>
            {ResumoBody(estado.dados.categorias, estado.loading)}
          </Container>
          <Container className='carousel'>
            {estado.loading ?
              <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
                <Skeleton className="carouselLoading"/>
              </SkeletonTheme>
              :
              <Carousel controls={false} indicators={true}>
                {BodyToCarousel(estado.dados.categorias)}
              </Carousel>}
          </ Container>
        </> :
        <></>
      }

    </section>
  )
}

const ResumoBody = (categorias?: Array<CategoriaType>, loading?: boolean) => {

  const categoriaPrincipal = categorias?.at(0);
  const categoriasSecundarias = categorias?.slice(1);

  return (
    <Row>
      <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
        {categoriaPrincipal ?
          <Col className="containerImagemPrincipal" lg={6}>
            <Link to={`/categorias/${categoriaPrincipal?.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(categoriaPrincipal?.title ?? '')}`}
              title={categoriaPrincipal?.title}>
              {loading ?
                <div className="categoria">
                  <Skeleton className='imagemPrincipalLoading' />
                </div>
                :
                <div className="categoria">
                  <img className='imagemPrincipal' src={categoriaPrincipal?.image} alt="imagem principal" loading="lazy" />
                  <div className='sombreamento' />
                  <p className="imagemTitulo">{categoriaPrincipal?.title}</p>
                </div>}
            </Link>
          </Col> : null}

        <Col className="containerImagemSecundaria" lg={3}>

          {categoriasSecundarias?.at(0) ?
            <Link to={`/categorias/${categoriasSecundarias?.at(0)?.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(categoriasSecundarias?.at(0)?.title ?? '')}`}
              title={categoriasSecundarias?.at(0)?.title}
              className="link">

              {loading ?
                <div className="categoria">
                  <Skeleton className='imagemSecundariaLoading' />
                </div>
                :
                <div className="categoria">
                  <img className='imagemSecundaria' src={categoriasSecundarias?.at(0)?.image} alt="" />
                  <div className='sombreamento' />
                  <p className="imagemTitulo">{categoriasSecundarias?.at(0)?.title}</p>
                </div>}

            </Link> : null}

          {categoriasSecundarias?.at(1) ?
            <Link to={`/categorias/${categoriasSecundarias?.at(1)?.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(categoriasSecundarias?.at(1)?.title ?? '')}`}
              title={categoriasSecundarias?.at(1)?.title}
              className="link">

              {loading ?
                <div className="categoria">
                  <Skeleton className='imagemSecundariaLoading' />
                </div>
                :
                <div className="categoria">
                  <img className='imagemSecundaria' src={categoriasSecundarias?.at(1)?.image} alt="" />
                  <div className='sombreamento' />
                  <p className="imagemTitulo">{categoriasSecundarias?.at(1)?.title}</p>
                </div>}

            </Link> : null}

        </Col>
        <Col className="containerImagemSecundaria" lg={3}>
          {categoriasSecundarias?.at(2) ?
            <Link to={`/categorias/${categoriasSecundarias?.at(2)?.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(categoriasSecundarias?.at(2)?.title ?? '')}`}
              title={categoriasSecundarias?.at(2)?.title}
              className="link">

              {loading ?
                <div className="categoria">
                  <Skeleton className='imagemSecundariaLoading' />
                </div>
                :
                <div className="categoria">
                  <img className='imagemSecundaria' src={categoriasSecundarias?.at(2)?.image} alt="" />
                  <div className='sombreamento' />
                  <p className="imagemTitulo">{categoriasSecundarias?.at(2)?.title}</p>
                </div>}

            </Link> : null}

          {categoriasSecundarias?.at(3) ?
            <Link to={`/categorias/${categoriasSecundarias?.at(3)?.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(categoriasSecundarias?.at(3)?.title ?? '')}`}
              title={categoriasSecundarias?.at(3)?.title}
              className="link">

              {loading ?
                <div className="categoria">
                  <Skeleton className='imagemSecundariaLoading' />
                </div>
                :
                <div className="categoria">
                  <img className='imagemSecundaria' src={categoriasSecundarias?.at(3)?.image} alt="" />
                  <div className='sombreamento' />
                  <p className="imagemTitulo">{categoriasSecundarias?.at(3)?.title}</p>
                </div>}

            </Link> : null}
        </Col>
      </SkeletonTheme>
    </Row>
  )
}


const BodyToCarousel = (categorias?: Array<CategoriaType>) => {

  return categorias?.slice(0, 5)?.map((categoria, index) =>
    <Carousel.Item key={categoria.id}>
      <Link to={`/categorias/${categoria.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(categoria.title ?? '')}`}
        title={categoria.title}>
        <div className="categoria">
          <div className='img'>
            <img src={categoria.image} alt={categoria.title} loading="lazy" />
          </div>
          <div className='sombreamento' />
          <p className="imagemTitulo">{categoria.title}</p>
        </div>
      </Link>
    </Carousel.Item>
  )
}
