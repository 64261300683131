import { SessaoHeader } from "../../componentes/sessoes/sessao-header/sessao-header";
import SessaoProdutosFavoritos from "../../componentes/sessoes/sessao-produtos-favoritos/sessao-produtos-favoritos";
import { useTitle } from "../../utilidades/tittle";

export default function PaginaProdutosFavoritos(){
  window.scroll(0,0);
  useTitle("Favoritos");
  return (
    <section className="PaginaProdutosFavoritos">
      <SessaoHeader variant="opaque" />
      <SessaoProdutosFavoritos/>
    </section>
  )
}