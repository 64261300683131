import { FAVORITOS_KEY } from '../constantes'

export const setarProdutoComoFavorito = (id: number) => {
  var dadosExistente = localStorage.getItem(FAVORITOS_KEY)
  var favoritos: number[] = []

  if(dadosExistente) favoritos = JSON.parse(dadosExistente)

  var idExisteEmFavoritos = !!favoritos.filter(favorito => favorito == id).length

  if(idExisteEmFavoritos) return

  favoritos.push(id)

  var favoritosComoString = JSON.stringify(favoritos)

  localStorage.setItem(FAVORITOS_KEY, favoritosComoString)
}

export const removerProdutoDosFavoritos = (id: number) => {
  var dadosExistente = localStorage.getItem(FAVORITOS_KEY)
  var favoritos: number[] = []

  if(!dadosExistente) return

  favoritos = JSON.parse(dadosExistente)
  
  favoritos = favoritos.filter(favorito => favorito != id)

  var favoritosComoString = JSON.stringify(favoritos)
  
  localStorage.setItem(FAVORITOS_KEY, favoritosComoString)
}

export const verificarSeProdutoEhFavorito = (id: number): boolean => {
  var dadosExistente = localStorage.getItem(FAVORITOS_KEY)

  if(!dadosExistente)  return false

  var favoritos = JSON.parse(dadosExistente)

  return !!favoritos.filter((favorito: number) => favorito === id).length
}

export function getProdutosFavoritos(){
  const valores = localStorage.getItem(FAVORITOS_KEY);
  
  return valores?.slice(1,valores.length-1)?.split(',');
}