import './App.scss';

import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useLocation } from "react-router-dom"

import { SessaoFooter } from './componentes/sessoes/sessao-footer/sessao-footer';
import { SessaoCopyright } from './componentes/sessoes/sessao-copyright/sessao-copyright';
import { PaginaHome } from './paginas/pagina-home/pagina-home';
import { PaginaSobre } from './paginas/pagina-sobre/pagina-sobre';
import { PaginaCategorias } from './paginas/pagina-categorias/pagina-categorias';
import { SessaoListagemCategorias } from './componentes/sessoes/sessao-listagem-categorias/sessao-listagem-categorias';
// import { SessaoSubcategorias } from './componentes/sessoes/sessao-subcategorias/sessao-subcategorias';
import { PaginaProdutos } from './paginas/pagina-produtos/pagina-produtos';
import { SessaoListagemProdutos } from './componentes/sessoes/sessao-listagem-produtos/sessao-listagem-produtos';
import { SessaoProduto } from './componentes/sessoes/sessao-produto/sessao-produto';
import { PaginaBlogs } from './paginas/pagina-blogs/pagina-blogs';
import { SessaoListagemBlogs } from './componentes/sessoes/sessao-listagem-blogs/sessao-listagem-blogs';
import { SessaoBlog } from './componentes/sessoes/sessao-blog/sessao-blog';
import { PaginaInspiracoes } from './paginas/pagina-inspiracoes/pagina-inspiracoes';
import { PaginaContato } from './paginas/pagina-contato/pagina-contato';
import { PaginaNaoEncontrada } from './paginas/pagina-nao-encontrada/pagina-nao-encontrada';
import { ThemeProvider } from 'react-bootstrap';
import PaginaProdutosFavoritos from './paginas/pagina-produtos-favoritos/pagina-produtos-favoritos';
import { SessaoSubcategorias } from './componentes/sessoes/sessao-subcategoria/sessao-subcategoria';
import { ScreenProvider } from './contexts/screen.context';
import { ModalPesquisaProvider } from './contexts/modal-pesquisa.context';
import { ModalPesquisa } from './componentes/modal-pesquisa/modal-pesquisa';
import { SessaoListagemProdutosPesquisa } from './componentes/sessoes/sessao-listagem-produtos-pesquisa/sessao-listagem-produtos-pesquisa';
import { SessaoWhatsapp } from './componentes/sessoes/sessao-whatsapp/sessao-whatsapp'
import { HeaderProvider, SubmenuProvider } from './componentes/sessoes/sessao-header/sessao-header-context';
import { Lgpd } from './componentes/lgpd/lgpd';
function App() {
  return (
    <ScreenProvider>
      <SubmenuProvider>
        <HeaderProvider>
          <ModalPesquisaProvider>
            <React.StrictMode>
              <ThemeProvider>
                <Router>
                  <ModalPesquisa />
                  <Routes>
                    <Route path="/" element={<PaginaHome />} />
                    <Route path="/favoritos" element={<PaginaProdutosFavoritos />} />
                    <Route path="/sobre-nos" element={<PaginaSobre />} />
                    <Route path="/categorias" element={<PaginaCategorias />}>
                      <Route path="" element={<SessaoListagemCategorias variant='normal' />} />
                      <Route path=":id/*" element={<SessaoSubcategorias />} />
                    </Route>
                    <Route path="/produtos" element={<PaginaProdutos />}>
                      <Route path=":subcategoriaId/:nomeSubcategoria" element={<SessaoListagemProdutos variant='normal' />} />
                      <Route path=":subcategoriaId/:nomeSubcategoria/:produtoId/:nomeProduto" element={<SessaoProduto />} />
                      <Route path="pesquisa" element={<SessaoListagemProdutosPesquisa variant='normal' />} />
                    </Route>
                    <Route path="/blog" element={<PaginaBlogs />}>
                      <Route path="" element={<SessaoListagemBlogs variant='normal' />} />
                      <Route path=":id/*" element={<SessaoBlog />} />
                    </Route>
                    <Route path="/inspiracoes" element={<PaginaInspiracoes />} />
                    <Route path="/fale-conosco" element={<PaginaContato />} />
                    <Route path="/404" element={<PaginaNaoEncontrada />} />
                    <Route path="*" element={<PaginaNaoEncontrada />} />
                  </Routes>
                  <SessaoWhatsapp />
                  <SessaoFooter />
                  <SessaoCopyright />
                  <Lgpd />
                </Router>
              </ThemeProvider>
            </React.StrictMode>
          </ModalPesquisaProvider>

        </HeaderProvider>
      </SubmenuProvider>
    </ScreenProvider >
  );
}

export default App;
