import parse from 'html-react-parser';
/**
 * Apenas um mapper para htmlEvent.preventDefault
 * @param event 
 */
export function pd(event: any) {
  event.preventDefault();
}

export function convertStringHtmlToReactHtml(htmlAsString: string): string | JSX.Element | JSX.Element[] {
  return parse(htmlAsString);
}

export const toTop = () => {
  document.documentElement.scrollTop = 0;
}

export function convertHtmlNodesToString(htmlNodeList: any): string {
  var str = '';
  htmlNodeList.forEach((child: any) => {
    if (child.outerHTML)
      str += child.outerHTML;
  });
  return str;
}

export function getChildNodesFromHtlmAsString(text: string): ChildNode[] {
  var res = new DOMParser().parseFromString(text, 'text/html')!.body!.childNodes;
  var childs = new Array<ChildNode>();

  res.forEach(e => childs.push(e));

  return childs;
}

/**
 *  Retorna todo o texto contido no html pai e seu filhos convertidos usando htmlAsString
 **/
export function getStringFromHtml(htmlAsString: string): string {
  var res = new DOMParser().parseFromString(htmlAsString, 'text/html')!.body!.textContent;
  return res ? res : ''
}

export const findPosition = (obj: any) => {
  var currenttop = 0;
  if (obj?.offsetParent) {
    do {
      currenttop += obj?.offsetTop;
    } while ((obj = obj?.offsetParent));
    return [currenttop];
  }
}

export const scrolldiv = (id: string, overflow: number = 0) => {
  var top = (findPosition(document.getElementById(id))![0] + overflow);
  document.documentElement.scrollTo({
    top: top,
    left: 0,
    behavior: "auto", // Optional if you want to skip the scrolling animation
  });
}

export const removeScroll = () => {
  (document.documentElement.style.overflow as any) = 'hidden';
  // (document.body.scroll as any) = 'no';
}

export const allowScroll = () => {
  (document.documentElement.style.overflow as any) = 'auto';
  (document.body.scroll as any) = 'yse';
}

export const disableMouseScroll = () => {
  document.getElementById('paginaHome')!.onmousedown = function(e){
    if(e.button == 1) {
      return false;
    }
  }
}

export const enableMouseScroll = () => {
  document.body.onmousedown = function(e){
    if(e.button == 1) {
      return true;
    }
  }
}

function extrairCodigo(str: string) {
  const regex = /<strong>Código: <\/strong>(\d+)/;
  const match = str.match(regex);
  if (match) {
    return match[1];
  } else {
    return null;
  }
}

export const extrairCodigoDeProduto = (str: string) => {
  return extrairCodigo(str);
}

function disableScroll() {
  // Get the current page scroll position
  var scrollTop = (window as any).pageYOffset || document.documentElement.scrollTop;
  var scrollLeft = (window as any).pageXOffset || document.documentElement.scrollLeft;

  // if any scroll is attempted, set this to the previous value
  (window as any).onscroll = function() {
      window!.scrollTo(scrollLeft, scrollTop);
  };
}

function enableScroll() {
  window.onscroll = function() {};
}

export const scrollView= (id: string) => {
  var top = (findPosition(document.getElementById(id))![0]);

  if(top >= 0){
    document.documentElement.scrollTo({
      top: top - 50,
      left: 0
    });
  }
  
}

export function converterParaUrlAmigavel(url: string): string {
  return url.toLowerCase()
    .replace(/[áàãâä]/g, 'a')
    .replace(/[éèẽêë]/g, 'e')
    .replace(/[íìîĩï]/g, 'i')
    .replace(/[óòõôö]/g, 'o')
    .replace(/[úùũûü]/g, 'u')
    .replace(/[ç]/g, 'c')
    .replace(/['"!@#$%¨&*()-+:<>=|\\/ºª°?]/g, '')
    .replace(/ /g, '-')
}