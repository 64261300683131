import React from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap'
import './sessao-nossa-historia.scss'
import parse from 'html-react-parser'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { COLORS } from '../../../themes/colors'

export type SessaoNossaHistoriaDataType = {
  ano: string | number,
  textoAsHtml: string,
  imgSrc: string
}

export const SessaoNossaHistoria = ({ dados, titulo, notFound }: { dados: SessaoNossaHistoriaDataType[], titulo: string, notFound: boolean }) => {

  return ( dados.at(0) ?
    <section id='sessaoNossaHistoria'>
      <Desktop dados={dados} titulo={titulo} />
      <Mobile dados={dados} titulo={titulo} />
    </section> 
    :
    notFound ? <></> : <section id='sessaoNossaHistoria'>
      <Container>
        <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
          <Skeleton className='loading'/>
        </SkeletonTheme>
      </Container>
    </section>
  )
}

const Desktop = ({ dados, titulo }: { dados: SessaoNossaHistoriaDataType[], titulo: string }) => {
  const [index, setIndex] = React.useState<number>(0)

  return (
    <>
      <Container className='desktop'>
        <div className='tag'>

        </div>
        <div className='tituloSection'>
          {titulo}
        </div>
        <Selectors index={index} setIndex={setIndex} dados={dados} />
        <Carousel controls={false} indicators={false} activeIndex={index}>
          {CarouselDesktopItems(index, setIndex, dados)}
        </Carousel>
        <Row className='rightBoxWallRow'>
          <Col xs={7} className='rightBoxWallCol'>
            <div></div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

const Selectors = ({ index, setIndex, dados }: { index: number, setIndex: (index: number) => void, dados: SessaoNossaHistoriaDataType[] }) => {
  return (
    <div className='selectorsLinha'>
      <div className='linha'>
        <div></div>
      </div>
      <div className='selectors'>
        {
          dados.map((data, i) =>
            <div className={`selector ${i == index ? 'active' : ''}`} onClick={() => setIndex(i)} key={i}>

            </div>
          )
        }
      </div>
    </div>
  )
}

const CarouselDesktopItems = (index: number, setIndex: (index: number) => void, dados: SessaoNossaHistoriaDataType[]) => {
  const [qtd, setQtd] = React.useState<number>(0)

  React.useEffect(() => {
    setQtd(dados.length)
  }, [dados])

  React.useEffect(() => setIndex(0), [dados]);

  const nextIndex = () => {
    setIndex((index + 1) % qtd)
  }

  const prevIndex = () => {
    setIndex(index <= 0 ? qtd - 1 : index - 1)
  }

  return dados.map((data, i) =>
    <Carousel.Item key={i}>
      <Row className='carouselDesktopItem'>
        <Col xs={6} className='leftBox'>
          <img src={data.imgSrc} alt={`imagem da nossa história em ${data.ano}`} />
        </Col>
        <Col xs={7} className='rightBox'>
          <div className='year'>
            {data.ano}
          </div>
          <div className='text'>
            {parse(data.textoAsHtml)}
          </div>
          <div className='buttons'>
            <button onClick={prevIndex}>
              <i className="bi bi-arrow-left" style={{ color: "white", fontSize: '1rem' }} ></i>
              {/* <ArrowLeft className='buttonIcon' /> */}
            </button>
            <button onClick={nextIndex}>
              <i className="bi bi-arrow-right" style={{ color: "white", fontSize: '1rem' }} ></i>
              {/* <ArrowRight className='buttonIcon' /> */}
            </button>
          </div>
        </Col>
      </Row>
    </Carousel.Item>
  )
}


const Mobile = ({ dados, titulo }: { dados: SessaoNossaHistoriaDataType[], titulo: string }) => {
  return (
    <Container className='mobile'>
      <div className='title'>
        <div className='tag'></div>
        {titulo}
      </div>
      <Carousel controls={false} indicators={true} interval={null}>
        {CorouselMobileItems(dados)}
      </Carousel>
    </Container>

  )
}

const CorouselMobileItems = (dados: SessaoNossaHistoriaDataType[]) => {
  return dados.map((data, i) =>
    <Carousel.Item key={i}>
      <Row className='carouselMobileItem'>
        <Col xs={12}>
          <div className='img'>
            <img src={data.imgSrc} alt={`imagem da nossa história em ${data.ano}`} />
          </div>
        </Col>
        <Col xs={12} className='year'>
          {data.ano}
        </Col>
        <Col xs={12} className='text'>
          {parse(data.textoAsHtml)}
        </Col>
      </Row>
    </Carousel.Item>
  )
}