import { useEffect, useLayoutEffect, useState } from "react";
import { Col, Collapse, Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { FiltroCaracteristica } from "../../modelos/types/type-filtro-caracteristica";
import { gestorReqs } from "../../servicos/gestor";
import "./menu-listagem-produtos.scss";

type ControleOption = {
  id: number;
  checked: boolean;
};

type Estado = {
  carregando: boolean;
  erro: boolean;
};

export const MenuListagemProdutos = ({
  filtrar,
  category,
  search,
}: {
  filtrar: (caracteristicasIds: number[], page: number) => void;
  category?: number;
  search?: string;
}) => {
  const [menus, setMenus] = useState<FiltroCaracteristica[]>([]);
  const [estado, setEstado] = useState<Estado>({
    carregando: true,
    erro: false,
  });
  const [menuControl, setMenuControl] = useState<ControleOption[][]>([]);
  const [humanClick, setHumanClick] = useState<boolean>(false);
  const [showResetBtn, setShowResetBtn] = useState<boolean>(false);

  const [caracteristicasPossiveis, setCaracteristicasPossiveis] = useState<
    FiltroCaracteristica[] | null
  >(null);

  useEffect(() => {
    setEstado({ carregando: true, erro: false });
    gestorReqs.getFiltrosCaracteristicas(category, search).then((response) => {
      if (!response.error && response.dados) {

        for (const i in response.dados){
          response.dados[i].characteristics = response.dados[i].characteristics.sort((a, b) => a.description.localeCompare(b.description));
        }

        setMenus(
          response.dados?.filter((m) => m.characteristics.length > 0) ?? []
        );
        setEstado({ carregando: false, erro: false });
      } else setEstado({ carregando: false, erro: true });
    });
  }, [, category, search]);

  useEffect(() => setHumanClick(false), [menus]);
  useLayoutEffect(() => {
    // -------- CRIA UM ARRAY QUE SERÁ USADO PARA CONTROLAR E MONITORAR OS CHECK BOXES ---------
    var controle: ControleOption[][] = [];
    for (let c of menus) {
      var optionsControle: ControleOption[] = [];
      for (let opt of c.characteristics) {
        optionsControle.push({ id: opt.id, checked: false });
      }
      controle.push(optionsControle);
    }
    setMenuControl([...controle]);
    // ---------------------------- END CRIAR CONTROLE CHECK BOXES -----------------------------
  }, [menus]);

  useEffect(() => {
    if (menuControl.length && menus.length && humanClick) {
      var caracteristicas: number[] = [];

      for (let c of menuControl)
        for (let opt of c) if (opt.checked) caracteristicas.push(opt.id);

      if (!caracteristicas.length) {
        if (window.innerWidth >= 992)
          setTimeout(() => setShowResetBtn(false), 750);
        else setShowResetBtn(false);
      } else setShowResetBtn(true);
      if (category)
        gestorReqs
          .getFiltrosPossiveis(category, caracteristicas)
          .then((res) => {
            setCaracteristicasPossiveis(
              res?.dados?.map(({ caracteristic, subCaracteristic }) => {
                return {
                  id: 0,
                  name: caracteristic,
                  characteristics: subCaracteristic,
                };
              }) ?? []
            );
          });
      filtrar(caracteristicas, 1);
    }
  }, [menuControl]);

  const trocarValorEGarantirUnicoChecked = (
    idxPai: number,
    idxOption: number
  ) => {
    var value = !menuControl[idxPai][idxOption].checked;
    menuControl[idxPai].forEach((option: ControleOption, i: number) => {
      if (i !== idxOption) option.checked = false;
      else option.checked = value;
    });

    setMenuControl([...menuControl]);
  };

  const resetarMenu = () => {
    for (let c of menuControl) for (let opt of c) opt.checked = false;
    setMenuControl([...menuControl]);
  };

  if (estado.erro) return <></>;

  return (
    <Container fluid id="menuListagemProdutos">
      {estado.carregando ? (
        <SkeletonsMenu />
      ) : (
        <Row>
          <Col>
            <button
              className={`resetarBotao`}
              onClick={() => resetarMenu()}
              disabled={!showResetBtn}
            >
              Limpar filtro
            </button>
          </Col>
          <Col xs={12} className="containerCaracteristicasSelecionadas">
            {menus?.reduce((selecionadas: JSX.Element[], item, index) => {
              item.characteristics.forEach(
                (subItem, indexSubCaracteristica) => {
                  if (
                    menuControl &&
                    menuControl[index] &&
                    menuControl[index][indexSubCaracteristica] &&
                    menuControl[index][indexSubCaracteristica].checked !==
                      undefined &&
                    menuControl[index][indexSubCaracteristica].checked
                  ) {
                    const { name } = item;
                    const { description } = subItem;
                    selecionadas = selecionadas.concat(
                      <div
                        className="caracteristicaSelecionada"
                        key={`${index} ${indexSubCaracteristica}`}
                      >
                        {/* <X
                          className="iconeFechar"
                          size={18}
                          onClick={() => {
                            setHumanClick(true);
                            trocarValorEGarantirUnicoChecked(
                              index,
                              indexSubCaracteristica
                            );
                          }}
                        /> */}
                        <i className="bi bi-x iconeFechar" style={{ fontSize: "1.8rem" }} onClick={() => {
                            setHumanClick(true);
                            trocarValorEGarantirUnicoChecked(
                              index,
                              indexSubCaracteristica
                            );
                          }}  ></i> 

                        <span>
                          {name}: {description}
                        </span>
                      </div>
                    );
                  }
                }
              );

              return selecionadas;
            }, [])}
          </Col>
          {menus.map((menu, i) => {
            const [caracteristicasPossivel] = caracteristicasPossiveis
              ? caracteristicasPossiveis?.filter(
                  ({ name }) => name === menu.name
                )
              : [null];

            return (
              <Col xs={12} className="menu" key={i}>
                <Toggle
                  header={
                    <div>
                      <span className="titulo">{menu.name}</span>
                      <hr />
                    </div>
                  }
                  body={
                    <div className="options">
                      {menu.characteristics.map((option, opIdx) => {
                        if (
                          caracteristicasPossivel?.characteristics.filter(
                            ({ id }) => option.id === id
                          ).length === 0
                        )
                          return null;

                        return (
                          menuControl &&
                          menuControl[i] &&
                          menuControl[i][opIdx] &&
                          menuControl[i][opIdx].checked !== undefined && (
                            <div
                              className="option"
                              key={option.description + opIdx}
                            >
                              <input
                                type="checkbox"
                                id={i + "checkbox" + opIdx}
                                name={i + "checkbox" + opIdx}
                                onChange={(e) => {
                                  trocarValorEGarantirUnicoChecked(i, opIdx);
                                }}
                                onClick={() => setHumanClick(true)}
                                checked={
                                  menuControl[i]
                                    ? menuControl[i][opIdx].checked
                                    : false
                                }
                              />
                              <label htmlFor={i + "checkbox" + opIdx}>
                                {option.description}
                              </label>
                            </div>
                          )
                        );
                      })}
                    </div>
                  }
                />
              </Col>
            );
          })}
        </Row>
      )}
    </Container>
  );
};

const Toggle = ({
  header,
  body,
}: {
  header: JSX.Element;
  body: JSX.Element;
}) => {
  const [activeStatus, setActiveStatus] = useState<boolean>(true);

  return (
    <>
      <Row>
        <Col
          className="toggleHeaderContainer"
          onClick={() => setActiveStatus(!activeStatus)}
        >
          <i className={`bi bi-chevron-up chevrom ${` ${activeStatus ? "up" : "down"}`}`} style={{ fontSize: "1.8rem" }} />
          {/* <ChevronUp
            className={`chevrom ${activeStatus ? "up" : "down"}`}
          /> */}
          <div>{header}</div>
        </Col>
      </Row>
      <Collapse in={activeStatus}>
        <Row>
          <Col>{body}</Col>
        </Row>
      </Collapse>
    </>
  );
};

const SkeletonsMenu = () => {
  return (
    <Row>
      {Array(4)
        .fill(null)
        .map((v, i) => (
          <Col xs={12} key={i} className="skeletonMenu">
            <Skeleton width={"100%"} height={"100%"} />
          </Col>
        ))}
    </Row>
  );
};
