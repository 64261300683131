import React from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { Banner, ThumbnailBanner } from '../../componentes/banner/banner'
import { CategoriaProdutoServicoType } from '../../modelos/types/type-categoria-produto'
import { SessaoHeader } from '../../componentes/sessoes/sessao-header/sessao-header'
import { GestorResponse } from '../../modelos/gestor-response'
import { gestorReqs } from '../../servicos/gestor'
import { useTitle } from '../../utilidades/tittle'
import './pagina-categorias.scss'
import { BannerInternoBreadcrumb } from '../../modelos/types/type-banner-interno'

export const PaginaCategorias = () => {
  const [thumbnailsBanner, setThumbnailsBanner] = React.useState<ThumbnailBanner[]>([])

  const categoria = useLocation().pathname.split('/').at(3);
  const { id } = useParams();

  useTitle("Categorias - Real Metais");

  React.useEffect(() => {
    gestorReqs.getBannerInterno(`categorias-${categoria}`).then((resposta) => {
      gestorReqs.getCategoriaPorId(Number(id)).then((catRes: GestorResponse<CategoriaProdutoServicoType>) => {
        if (resposta.error || catRes.error) return;
        setThumbnailsBanner([{
          title: catRes.dados?.title,
          img: resposta.dados?.at(0)?.imagensArray.at(0)?.src ?? "",
          breadcrumbs: resposta.dados?.[0]?.breadcrumbs?.sort((a, b) => a.order <= b.order ? -1 : 1).map((bread: BannerInternoBreadcrumb) => (
            {
              name: bread.name,
              url: bread.href
            }
          )) ?? [],
          description: catRes?.dados?.description
        }])
      })
    })
  }, [])

  return (
    <section id="paginaCategorias">
      <SessaoHeader variant='opaque' />
      <Banner variant='contido' thumbnails={thumbnailsBanner} />
      <Outlet />
    </section>
  )
}
