import './sessao-header.scss'
import { Accordion, Col, Container, Navbar, Offcanvas, Row } from 'react-bootstrap';
import { MenuTree } from '../../../modelos/menu';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SessaoAcessoRapido } from '../sessao-acesso-rapido/sessao-acesso-rapido';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { COLORS } from '../../../themes/colors';
import 'react-loading-skeleton/dist/skeleton.css'
import { useModalPesquisaContext } from '../../../contexts/modal-pesquisa.context';
import { useHeaderContext, useSubmenuContext } from './sessao-header-context';

import Logo from '../../../assets/imagens/realmetais_logo_tagline_verde.png';

export type SessaoHeaderVariant = 'transparent' | 'opaque'

export type AcessoRapidoData = {
  telefone: string
  email: string
  portal: string
  facebook: string
  youtube: string
  instagram: string
  logo: string
}

export type Estado = {
  carregando: boolean,
  erro: boolean,
}

export const SessaoHeader = ({ variant, acessoRapido }: { variant: SessaoHeaderVariant, acessoRapido?: boolean }) => {


  const [showSideBar, setShowSideBar] = React.useState<boolean>(false)

  const [esconderAcessoRapido, setEsconderAcessoRapido] = React.useState<Boolean>(false)
  const [scrolled, setScrolled] = React.useState<Boolean>(false)


  const [isMobile, setIsMobile] = React.useState<boolean>(false)

  const { setShow: setShowModal } = useModalPesquisaContext()

  const { menus, setMenus } = useHeaderContext()
  const { estado, setEstado } = useHeaderContext()
  const { acessoRapidoData, setAcessoRapidoData } = useHeaderContext()
  const { submenuAberto, setSubmenuAberto } = useSubmenuContext()

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 991.98) {
        setShowSideBar(false)
        setIsMobile(false)
      } else setIsMobile(true)


    }

    function listenScroll() {
      const scroll = document.body.scrollTop || document.documentElement.scrollTop

      if (variant == 'transparent' && scroll > 100) {
        setScrolled(true)
        setEsconderAcessoRapido(true)
      }
      else {
        setScrolled(false)
        setEsconderAcessoRapido(false)
      }
    }

    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', listenScroll)
    handleResize()
  }, [])

  const swapShowSideBar = () => {
    setShowSideBar(!showSideBar)
  }

  const HandlerSideBar = () => {
    return (
      <button className='handler' onClick={() => swapShowSideBar()}>
        {/* <XLg /> */}
        <i className="bi bi-x-lg" style={{ fontSize: '1.5rem' }} ></i>
      </button>
    )
  }

  const Hambugger = () => {
    return (
      <button className='hambugger' onClick={() => swapShowSideBar()}>
        {/* <List /> */}
        <i className="bi bi-list" style={{ fontSize: '1.5rem' }} ></i>
      </button>
    )
  }

  const showModalPesquisa = () => {
    setShowSideBar(false)
    setShowModal(true)
  }

  const toggleSubmenu = () => {
    if(window.innerWidth >= 992) setSubmenuAberto(!submenuAberto)
  }


  return (
    <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
      <section id='headerBox' className={`${variant == 'transparent' ? scrolled ? 'opaque' : variant : variant} ${scrolled ? 'scrolled' : ''}`}>
        {acessoRapido && !esconderAcessoRapido && !isMobile &&
          <SessaoAcessoRapido input={acessoRapidoData!} estado={estado} isMobile={isMobile} />
        }
        {!estado.erro &&
          <div id='sessaoHeaderSide' className={`${variant == 'transparent' ? scrolled ? 'opaque' : variant : variant}`}>
            <Navbar expand='lg' fixed='top' expanded={showSideBar}>
              <Container>
                <Navbar.Brand href="/">
                    {/* <div className='img'>
                      <img src={acessoRapidoData?.logo} alt="logotipo real metais" />
                    </div> */}
                  {estado.carregando ? <ImageSkeleton /> :
                    <div className='img'>
                      <img src={scrolled ? Logo : acessoRapidoData?.logo} alt="logotipo real metais" />
                    </div>
                  }
                </Navbar.Brand>
                <Hambugger />
                <Navbar.Offcanvas id="menu">
                  <Offcanvas.Header><HandlerSideBar /></Offcanvas.Header>
                  <Offcanvas.Body className="mr-auto">
                    {
                      estado.carregando ? Array(5).fill(null).map((v, i) => <OptionSkeleton key={i} />) :
                        menus?.map((menu, i) =>
                          <MenuOptionA menuTree={menu} key={'A' + i} last={i == menus.length - 1} toggleSubmenu={toggleSubmenu}/>
                        )
                    }

                    {
                      <div className='acessoRapido'>
                        {/* {JSON.stringify(acessoRapidoData)}
                        {JSON.stringify(estado)} */}
                        <SessaoAcessoRapido input={acessoRapidoData!} estado={estado} isMobile={isMobile} />
                      </div>
                    }

                    
                    {estado.carregando ? <IconsMenuSkeleton /> :
                      <div className='icons'>
                        <a href={acessoRapidoData?.instagram} target='blank'>
                          {/* <Instagram className='icon acessoRapido' /> */}
                          <i className="bi bi-instagram icon acessoRapido" ></i>
                        </a>
                        <a href={acessoRapidoData?.facebook} target='blank'>
                          {/* <Facebook className='icon acessoRapido' /> */}
                          <i className="bi bi-facebook icon acessoRapido" ></i>
                        </a>
                        <a href={acessoRapidoData?.youtube} target='blank'>
                          {/* <Youtube className='icon acessoRapido' /> */}
                          <i className="bi bi-youtube icon acessoRapido" ></i>
                        </a>
                        <Link title="Favoritos" to='/favoritos'>
                          {/* <Heart className='icon' /> */}
                          <i className="bi bi-heart icon" style={{color:"white"}} ></i>
                        </Link>
                        <div>
                          {/* <Search className='icon' onClick={() => showModalPesquisa()} /> */}
                          <i className="bi bi-search icon" onClick={() => showModalPesquisa()} ></i>
                        </div>
                      </div>
                    }
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          </div>
        }
      </section>
    </SkeletonTheme>
  )
}

const MenuOptionA = ({ menuTree, last, toggleSubmenu }: { menuTree: MenuTree, last: Boolean, toggleSubmenu: () => void }) => {


  const [active, setActive] = useState('-1')

  const close = () => {
    setActive('-1')
  }

  /*
    Não aplica capitalize em predicados
  */
  const capitalize = (fhrase: string) => {
    const words = fhrase.toLowerCase().split(' ');

    const notCapitalize = ['para', 'como', 'com', 'e', 'de', 'em']

    return words.reduce(
      (acc, cv) => acc + ((cv.length > 2) && !notCapitalize.includes(cv) ? ` ${cv[0].toUpperCase()}${cv.slice(1)}`: ` ${cv}`),
      ''
    ).slice(1);
  }

  if (menuTree.menu.isLink) {
    return menuTree.menu.html !== '/fale-conosco' ?
      <a title={menuTree.menu.name} className={`linkA ${last ? 'lastA' : ''}`} href={menuTree.menu.html ?? ''}
        target={menuTree.menu.html?.includes('https://') || menuTree.menu.html?.includes('http://') ? '#' : ''}>
        {menuTree.menu.name}
      </a> :
      <a title={menuTree.menu.name} className={`linkA ${last ? 'lastA' : ''}`} href={menuTree.menu.html ?? ''}
        target={menuTree.menu.html?.includes('https://') || menuTree.menu.html?.includes('http://') ? '#' : ''}>
        {menuTree.menu.name}
      </a>
  } else {
    return (
      <Accordion className={`linkA accordion ${last ? 'lastA' : ''}`} activeKey={active}>
        <Accordion.Item eventKey="0" onClick={() => setActive(active === '0' ? '-1' : '0')}>
          <Accordion.Header onClick={toggleSubmenu}>
            {menuTree.menu.name}
            <i className="bi bi-chevron-down expandIcon" ></i>
            <i className="bi bi-chevron-up recallIcon" ></i>
            {/* <ChevronDown className='expandIcon' />
            <ChevronUp className='recallIcon' /> */}
          </Accordion.Header>
          <Accordion.Body className='acordionBody'>
            <ul className='listaDeProdutos'>
              {
                menuTree.childrens.map((mt, i) => {
                  return (
                    <li key={i} className='produto'>
                      <a className='link' title={mt.menu.name} href={mt.menu.html}>
                        <span className='nome'>
                          {capitalize(mt.menu.name ?? '')}
                          {/* <ChevronRight className='icone'/> */}
                          <i className="bi bi-chevron-right expandIcon" ></i>
                        </span>
                      </a>
                      <Container className='containerCategorias'>
                        <Row>
                          <Col className='titulo'>
                            <a className='link' href={mt.menu.html} title={mt.menu.name}>
                              <span className='nome'>
                                {capitalize(mt.menu.name ?? '')}
                              </span>
                            </a>
                          </Col>
                        </Row>
                        <Row>
                          {mt.childrens.map((categoria, index) => {
                            return (
                              <Col className="subTitulo" key={index} lg={12} xs={6} sm={6}>
                                <a
                                  className='link'
                                  title={categoria.menu.name}
                                  href={categoria.menu.html ?? ""}>
                                  <span className='nome'>
                                    {capitalize(categoria.menu.name ?? '')}
                                  </span>
                                </a>
                              </Col>
                            )
                          })}
                        </Row>
                      </Container>
                    </li>
                  )
                })}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    )
  }
}

const MenuOptionB = ({ menuTree, close }: { menuTree: MenuTree, close: () => void }) => {
  return (
    <div className='linkB'>
      <>
        <span >{menuTree.menu.name}</span>
        <div>
          {
            menuTree.childrens.map((mt, i) =>
              <a title={mt.menu.name} className='linkC' href={mt.menu.html ?? ''} key={'C' + i} onClick={close}>
                {mt.menu.name}
              </a>
            )
          }
        </div>
      </>
    </div>

  )
}

// --------- skeletons --------------

const ImageSkeleton = () => {
  return (
    <div className='imageSkeleton'>
      <Skeleton width={'100%'} height={'100%'} />
    </div>
  )
}

const OptionSkeleton = () => {
  return (
    <div className='OptionSkeleton'>
      <Skeleton width={'100%'} height={'100%'} />
    </div>
  )
}

const IconsMenuSkeleton = () => {
  return (
    <div className='IconsMenuSkeleton'>
      <Skeleton width={'100%'} height={'100%'} />
    </div>
  )
}
