import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react"
import { Button, Col, Container, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { CategoriaProdutoServicoType } from "../../../modelos/types/type-categoria-produto";
import { ProdutoServicoType } from "../../../modelos/types/type-produto";
import { gestorReqs } from "../../../servicos/gestor";
import { COLORS } from "../../../themes/colors";
import { FuncoesAuxiliares } from "../../../utilidades/funcoes-auxiliares";
import { getProdutosFavoritos } from "../../../utilidades/manipuladores-local-storage";
import { ProdutoCard } from "../../produto-card/produto-card";
import "./sessao-produtos-favoritos.scss"

export default function SessaoProdutosFavoritos() {

  type SessaoProdutosFavoritosType = {
    loading: boolean,
    erroAoCarregar: boolean,
    dados: {
      titulo?: string,
      descricao?: string,
      categorias?: Array<CategoriaProdutoServicoType>,
      produtosFavoritos: Array<ProdutoServicoType | undefined>
    }
  }

  const [estado, setEstado] = useState<SessaoProdutosFavoritosType>({
    loading: true,
    erroAoCarregar: false,
    dados: {
      produtosFavoritos: []
    }
  });

  const existeItemFavorito = !!(getProdutosFavoritos()?.filter(id => id != ''))

  useEffect(() => {

    gestorReqs.getSections("favoritos").then(resposta => {

      if (!resposta.at(0)?.section_informations.at(0)) {
        estado.erroAoCarregar = true;
        setEstado({ ...estado });
        return;
      }

      estado.dados.titulo = resposta.at(0)?.section_informations.at(0)?.section_data?.titulo;
      estado.dados.descricao = resposta.at(0)?.section_informations.at(0)?.section_data?.descricao;

      if (!existeItemFavorito) {
        estado.loading = false;
        setEstado({ ...estado });
      }
      gestorReqs.getCategoriasProdutoServico().then(resposta => {
        estado.dados.categorias = resposta.dados;
        estado.loading = false
        setEstado({ ...estado });
      });

      getProdutosFavoritos()?.forEach((id, index) => {

        if (id === '') return;

        gestorReqs.getProdutoById(Number(id)).then((resposta) => {
          estado.dados.produtosFavoritos[index] = resposta?.dados;
          estado.loading = false;
          setEstado({ ...estado });
        })
      })
    });


  }, [])


  if (estado.erroAoCarregar)
    return null;

  return (
    <section id="SessaoProdutosFavoritos">
      {estado.loading ?
        <Container className="containerLoading">
          <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
            <Row className="containerEscritaLoading">
              <Col lg={6}>
                <Skeleton className="tituloLoading" />
                <Skeleton className="caminhoLoading" />
                <Skeleton className="textoLoading" count={5} />
              </Col>
            </Row>
            <Row className="containerCardLoading">
              <Col lg={3}>
                <Skeleton className="cardLoading" />
              </Col>
              <Col lg={3}>
                <Skeleton className="cardLoading" />
              </Col>
              <Col lg={3}>
                <Skeleton className="cardLoading" />
              </Col>
              <Col lg={3}>
                <Skeleton className="cardLoading" />
              </Col>
            </Row>
          </SkeletonTheme>
        </Container>
        :
        <Container>
          <Row className="containerTexto">
            <Col lg={6}>
              <h1 className="titulo">
                {estado.dados.titulo}
              </h1>
              <h3 className="caminho">
                HOME {'>'} FAVORITOS
              </h3>
              <div className="texto">
                {HTMLReactParser(estado.dados.descricao ?? "")}
              </div>
            </Col>
          </Row>
          <Row className="containerProdutos">
            {existeItemFavorito ?
              estado.dados.produtosFavoritos.map((produto, index) => {

                const idCategoria = estado.dados.categorias?.filter(categoria => categoria.title === produto?.category).at(0)?.id;
                const categoria = FuncoesAuxiliares.toUrlAmigavelConverter(produto?.category ?? '');

                const idProduto = produto?.id;
                const nomeProduto = FuncoesAuxiliares.toUrlAmigavelConverter(produto?.title ?? '');
                
                const fichaCadastral = produto?.arquivosArray.filter(arquivo => arquivo.src.includes(".xlsx"))?.at(0)?.src;

                return <Col key={index} lg={3} className="containerProduto">
                  <ProdutoCard dados={{
                    descricao: produto?.description ?? "",
                    id: Number(produto?.id) ?? 0,
                    imagem: produto?.imagensArray.at(0)?.src ?? "",
                    link: `/produtos/${idCategoria}/${categoria}/${idProduto}/${nomeProduto}`,
                    titulo: produto?.title ?? "",
                    arquivoCadastro: fichaCadastral
                  }} />
                </Col>;
              })
              :
              <Row>
                <Col lg={12}>
                  <h2>
                    Você ainda não adicionou produto na sua lista de favoritos
                  </h2>
                </Col>
              </Row>
            }
          </Row>
        </Container>}
    </section>
  )
}