import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ListagemBlogs, SecaoListagemBlogsType } from '../../listagem-blogs/listagem-blogs'
import { MenuTabela, MenuTabelaType } from '../../menu-tabela/menu-tabela'
import './sessao-listagem-blogs.scss'

import { gestorReqs } from '../../../servicos/gestor'
import { Blog } from '../../../modelos/blog'

import { getStringFromHtml, scrolldiv, toTop } from '../../../utilidades/manipuladores-html'
import { FuncoesAuxiliares, toUrlAmigavelConverter } from '../../../utilidades/funcoes-auxiliares'
import { Seletores } from '../../seletores/seletores'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTitle } from '../../../utilidades/tittle'

export type SessaoListagemBlogsVariant = 'resumo' | 'normal' | 'relacionados'

export const SessaoListagemBlogs = ({ variant, titulo, categoriaId }: { variant: SessaoListagemBlogsVariant, titulo?: string, categoriaId?: number }) => {

   if (variant === 'resumo')
      return (<SessaoListagemBlogsResumo titulo={titulo} categoriaId={categoriaId} />)

   else if (variant === 'normal')
      return (<SessaoListagemBlogsNormal />)

   return (
      <section id='sessaoListagemBlogs'>
         Sessao Listagem Blogs Works - {variant}
         {/* <MenuTabela /> */}
         {/* <ListagemBlogs variant={variant} /> */}
      </section>
   )
}

const SessaoListagemBlogsNormal = () => {
   const blogsPorPagina = 8
   const [dados, setDados] = useState<SecaoListagemBlogsType[]>()
   const [total, setTotal] = useState<number>(0)
   const [selectedPage, setSelectedPage] = useState<number>(1)

   const [dadosMenuTabela, setDadosMenuTabela] = useState<MenuTabelaType[]>()

   const search = useLocation().search
   const categoriaId = new URLSearchParams(search).get('category');

   useTitle("Blog");

   useEffect(() => {
      toTop()
      gestorReqs.getCategories().then(response => {
         var menuTabelaDados: MenuTabelaType[] = response.map(c => ({ ...c, qtd: c.quantity } as MenuTabelaType))

         menuTabelaDados.forEach((m, i) => {
            if (m.id === -1) {
               var aux = menuTabelaDados[0];
               menuTabelaDados[0] = m;
               menuTabelaDados[i] = aux;
            }
         })

         setDadosMenuTabela(menuTabelaDados)
      })
   }, [])

   useEffect(() => {
      setDados(undefined);
      gestorReqs.searchBlogsByCategory(1, blogsPorPagina, Number(categoriaId ? categoriaId : -1)).then(response => {
         var dadosAux = response.resultados.map(blog => convertBlogToSecaoListagemBlogType(blog))
         setDados(dadosAux)
         setTotal(response.totalResultados)
         setSelectedPage(1)
         //scrolldiv('ancora-listagem-blogs-top', -150);
      })
   }, [categoriaId])

   useEffect(() => {
      gestorReqs.searchBlogsByCategory(selectedPage, blogsPorPagina, Number(categoriaId ? categoriaId : -1)).then(response => {
         var dadosAux = response.resultados.map(blog => convertBlogToSecaoListagemBlogType(blog))
         setDados(dadosAux)
         setTotal(response.totalResultados)
      })
   }, [selectedPage])

   const changePageHandler = (index: number) => {
      setDados(undefined);
      scrolldiv('ancora-listagem-blogs-top', -150);
      setSelectedPage(index)
   }

   const scrolMudarCategoria = () => scrolldiv('ancora-listagem-blogs-top', -150);

   return (
      <section id='sessaoListagemBlogs' className='normal'>
         <div id='ancora-listagem-blogs-top'></div>
         <Container>
            <Row className='layout'>
               <Col className='listagemBox' xs={12} lg={8}>
                  {<ListagemBlogs variant='normal' dados={dados} />}
                  {total > 8 ?
                     <Seletores
                        total={total}
                        itemsPerPage={blogsPorPagina}
                        selected={selectedPage}
                        middle={3}
                        fixeds={1}
                        changePage={changePageHandler}
                     /> : null}
               </Col>
               <Col className='tabelaBox' xs={12} lg={4}>
                  <MenuTabela queryParam='category' dados={dadosMenuTabela} boldSelected={true} changeCategoria={scrolMudarCategoria} />
               </Col>
            </Row>
         </Container>
      </section>
   )
}

const convertBlogToSecaoListagemBlogType = (blog: Blog) => {
   var startDate = blog.start_date
   var subtitulo = ''

   if (startDate) {
      subtitulo = FuncoesAuxiliares.dataParaEscrita(`${startDate.getDate()}/${startDate.getMonth()}/${startDate.getFullYear()}`) ?? "";
   }

   var SLBT: SecaoListagemBlogsType = {
      subtitulo: subtitulo,
      titulo: blog.title ? blog.title : '',
      texto: blog.description ? getStringFromHtml(blog.description ? blog.description : '') : '',
      imgSrc: blog!.imagens![0]!.src!,
      link: `/blog/${blog.id}/${toUrlAmigavelConverter(blog.title ? blog.title : '')}`
   }

   return SLBT;
}


const SessaoListagemBlogsResumo = ({ titulo, categoriaId }: { titulo?: string, categoriaId?: number }) => {

   const [dados, setDados] = useState<SecaoListagemBlogsType[]>()
   const { id } = useParams()

   function searchBlogs() {
      if (!categoriaId || categoriaId == -1)
         gestorReqs.getBlogsPageble(1, 9).then(response => {
            var dadosAux = response.resultados.map(blog => convertBlogToSecaoListagemBlogType(blog))
            setDados(dadosAux);
         })
      else gestorReqs.searchBlogsByCategory(1, 10, categoriaId).then(response => {
         var dadosAux = response.resultados.filter(blog => blog.id != id).map(blog => convertBlogToSecaoListagemBlogType(blog))
         setDados(dadosAux);
      })
   }

   useEffect(() => searchBlogs(), [categoriaId, id])


   return (
      dados && dados.length ?
         <section id='sessaoListagemBlogs' className='resumo'>
            {titulo &&
               <Container className='titulo'>
                  <div className='tag'></div>
                  <span>{titulo}</span>
               </Container>
            }
            <ListagemBlogs variant='resumo' dados={dados} />
         </section>
         : <></>
   )
}


