import { Alert, Col, Container, InputGroup, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./modal-cadastro-newsletter.scss";
import { gestorReqs } from "../../servicos/gestor";
import { useState } from "react";
import { SubscribeRespostaEnum } from "../../modelos/subscribe";

function validaEmail(email?: string) {

  const regex = new RegExp('^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$');

  if (regex.test(email?.toLowerCase() as string)) return true;

  return false;
}

export const ModalCadastroNewsletter = () => {

  type ModalCadastroNewsletterType = {
    controle: {
      email: string,
      botaoEscrita:string,
      mensagemResposta:string
    }
  }

  const [estado, setEstado] = useState<ModalCadastroNewsletterType>({
    controle: {
      email: "",
      botaoEscrita:"CADASTRAR",
      mensagemResposta:""
    }
  })

  function enviarEmail(event: any) {
    event.preventDefault();
    
    if(!validaEmail(estado.controle.email)){

      if(estado.controle.email === ""){
        estado.controle.botaoEscrita = "CADASTRAR";
        estado.controle.mensagemResposta = "Email vazio !"
        setEstado({...estado});
        return
      }

      estado.controle.botaoEscrita = "CADASTRAR";
      estado.controle.mensagemResposta = "Email invalido !"
      setEstado({...estado});
      return;
    }
    
    estado.controle.botaoEscrita = "ENVIANDO";
    setEstado({...estado});

    gestorReqs.postEmailNewsletter(estado.controle.email).then((resposta)=>{

      if(resposta === SubscribeRespostaEnum.OK){

        estado.controle.botaoEscrita = "OK";
        estado.controle.mensagemResposta = "Email cadastrado !"

        setEstado({...estado});

        return;
      }

      if(resposta === SubscribeRespostaEnum.EMAIL_EXISTENTE){

        estado.controle.botaoEscrita = "OK";
        estado.controle.mensagemResposta = "Email já cadastrado !"
        setEstado({...estado});

        return;
      }

      if(resposta === SubscribeRespostaEnum.VAZIO){

        estado.controle.botaoEscrita = "ERRO";
        estado.controle.mensagemResposta = "Email não informado !"
        setEstado({...estado});

        return;
      }

      if(resposta === SubscribeRespostaEnum.ERRO_INDEFINIDO){

        estado.controle.botaoEscrita = "ERRO";
        estado.controle.mensagemResposta = "Problema para cadastrar email !"
        setEstado({...estado});

        return;
      }
    });

    return;
  }

  return (
    <section id="modalCadastroNewsletter">
      <Container className="containerNewsletter">
      
        <Row>
          <Col lg={4} className="itemNewsletter">
            <h3 className="textoNewsletter">
              CADASTRE SEU E-MAIL E RECEBA OFERTAS E NOVIDADES
            </h3>
          </Col>
          <Col lg={8} className="itemNewsletter">

            <Form noValidate onSubmit={enviarEmail}>
              <Row>
                <Col lg={9} className="colInputNewsletter">
                  <Form.Group>
                    <Form.Control
                      id="Email"
                      required
                      type="email"
                      placeholder="Insira seu e-mail"
                      value={estado.controle.email}
                      onChange={(event) => {
                        estado.controle.email = event.target.value;
                        setEstado({ ...estado });
                      }}
                      isValid={validaEmail(estado.controle.email)}
                      isInvalid={!validaEmail(estado.controle.email) && estado.controle.email != ""}
                    />
                    <p className="mensagem">{estado.controle.mensagemResposta}</p>
                  </Form.Group>
                </Col>
                <Col lg={3} className="colBtnNewsletter">
                  <Form.Group className="grupoBtn">
                    <Button
                      title="enviar"
                      type="submit">
                      {estado.controle.botaoEscrita}
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        
      </Container>
    </section>
  );
};
