import { IDeserializable } from './deserializable';
import { Image } from './image';
import { ArquivoType } from './types/type-arquivo';

export class Blog implements IDeserializable{
    id?: number;
    title?: string;
    description?: string;
    author?: string;
    company?: number;
    createdAt?: Date;
    updatedAt?: Date;
    start_date?: Date;
    end_date?: Date;
    category?: string;
    tags?: string[];
    disabled?: boolean;
    imagens?: Image[];
    arquivos?: ArquivoType[];

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}