import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { SessaoHeader } from '../../componentes/sessoes/sessao-header/sessao-header'
import { useTitle } from '../../utilidades/tittle'
import './pagina-nao-encontrada.scss'
import { useEffect } from 'react'

export const PaginaNaoEncontrada = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  useTitle("Não Encontrada");

  useEffect(() => {
    navigate({
      pathname: '/404',
      search: `?${location.pathname }`,
    });
  }, [ ])
  
  return (
    <section id='paginaNaoEncontrada'>
      <SessaoHeader variant='opaque' />
      <div className='ancora'></div>
      <Container>
        <Row>
          <Col className="container">
            <h1 className='titulo'>
              Conteudo não existente !
              <br/>
              <code>{params}</code>
            </h1>
            <Link to="">
              <Button className='botao'>
                Retornar ao inicio
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  )
}