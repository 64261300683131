import "./garantia-produto.scss";
import { ListGroup } from "react-bootstrap";


export const GarantiaProduto = () => {

    return (
        <>
            <ListGroup variant="flush" className="garantia-produto">
                <ListGroup.Item className="listItemP">
                    <strong>A garantia não será aplicada nas seguintes situações:</strong>
                </ListGroup.Item>
                <ListGroup.Item className="listItemS">
                    &bull; Danos sofridos pelo produto em consequência de maus tratos, quedas acidentais, manuseio inadequado, instalação incorreta e erro de especificação.
                </ListGroup.Item>
                <ListGroup.Item className="listItemS">
                    &bull; Danos causados aos acabamentos devido a limpeza inadequada com produtos químicos, solventes, abrasivos tipo saponáceo, palha de aço, esponja dupla face e outros.
                </ListGroup.Item>
                <ListGroup.Item className="listItemS">
                    &bull; Produtos com corpos estranhos em seu interior tais como: cola, lubrificantes, detritos, fita veda rosca, estopa e outros que comprometam o seu correto funcionamento.
                </ListGroup.Item>
                <ListGroup.Item className="listItemS">
                    &bull; Produto instalado em locais onde a água é considerada não potável ou que contenha substâncias ou impurezas estranhas à mesma, que comprometam o bom funcionamento do mesmo.
                </ListGroup.Item>
                <ListGroup.Item className="listItemS">
                    &bull; Peças que apresentam desgaste natural de uso, tais como: vedantes, gaxetas, anéis de vedação, guarnições, cunhas e mecanismos de vedação.
                </ListGroup.Item>
                <ListGroup.Item className="listItemS">
                    &bull; Produtos reparados por pessoas não especializadas.
                </ListGroup.Item>
                <ListGroup.Item className="listItemS">
                    &bull; Aplicação de peças não originais, peças inadequadas, adaptações ou modificações não autorizadas.
                </ListGroup.Item>
                <ListGroup.Item className="listItemP">
                    <strong>Atenção:</strong> Para limpeza dos acabamentos, utilize apenas pano macio, água e sabão neutro.
                </ListGroup.Item>
            </ListGroup>
        </>
    );
}