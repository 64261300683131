import { Col, Container, Row } from "react-bootstrap";
import { ModalCadastroNewsletter } from "../../modal-cadastro-newsletter/modal-cadastro-newsletter";
import logotipo from "../../../assets/imagens/logotipoRodape.png";
import Accordion from "react-bootstrap/Accordion";
import { gestorReqs } from "../../../servicos/gestor";
import { useEffect, useState } from "react";
import "./sessao-footer.scss";
import { MenuTree } from "../../../modelos/menu";
import { Link } from "react-router-dom";
import { FuncoesAuxiliares } from "../../../utilidades/funcoes-auxiliares";
import { useHeaderContext } from "../sessao-header/sessao-header-context";

type DadosInformacoes = {
  state: string;
  city: string;
  address: string;
  cep: string;
  number: number;
  neighborhood: string;
  whatsapp: string;
  email: string;
  cnpj: string;
  watermark: string
  phones: Array<{
    phone: string;
  }>;
  social_media: Array<{
    link: string
  }>;
};

type DadosMenu = {
  name: string;
  link: string;
};

export const SessaoFooter = () => {
  const [dados, setDados] = useState<DadosInformacoes>();
  const [menus, setMenus] = useState<DadosMenu[]>();
  const [produtos, setProdutos] = useState<DadosMenu[]>();
  const { acessoRapidoData, setAcessoRapidoData } = useHeaderContext()

  useEffect(() => {
    gestorReqs.getInformacoes().then((response) => {
      setDados(response as DadosInformacoes);
    });

    gestorReqs.getMenus().then((response) => {
      var mtMenusFiltrado: MenuTree[] = response.dados!.filter(
        (mt) => mt.menu.isLink && mt.menu.name !== "HOME"
      );

      var aux: DadosMenu[] = mtMenusFiltrado.map((mt) => {
        return {
          name: mt.menu.name ?? "",
          link: mt.menu.html ?? "",
        };
      });
      setMenus(aux);
    });

    gestorReqs.getCategorias().then(resposta => {
      setProdutos(resposta.dados?.filter((c: any) => c?.category_id == null).map(categoria => {
        return {
          name: categoria.title ?? "",
          link: `categorias/${categoria.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(categoria.title ?? "")}`
        }
      }))

    })
  }, []);

  return (
    <section id="sessaoFooter">
      <ModalCadastroNewsletter />
      <Container>
        <Row className="desktop footerRow">
          <Col lg={3} className="itemRodape">
            <Link title="HOME" to="/" reloadDocument>
              <div className="imgRodape">
                <img src={dados?.watermark} alt="logo" className="logo" width="100%" height="100%" />
              </div>
            </Link>
            {dados && <div className="iconesRodape">
              <a title="Instagram" href={dados?.social_media[0].link ?? ''} target="blank">
                {/* <Instagram className="iconeRedeSociais" /> */}
                <i className="bi bi-instagram" style={{ color: "white", fontSize: '1.6rem' }} ></i>
              </a>
              <a title="Facebook" href={dados?.social_media[1].link ?? ''} target="blank">
                {/* <Facebook className="iconeRedeSociais" /> */}
                <i className="bi bi-facebook" style={{ color: "white", fontSize: '1.6rem' }} ></i>
              </a>
              <a title="Youtube" href={dados?.social_media[2].link ?? ''} target="blank">
                {/* <Youtube className="iconeRedeSociais" /> */}
                <i className="bi bi-youtube" style={{ color: "white", fontSize: '1.6rem' }} ></i>
              </a>
            </div>}
          </Col>

          <Col lg={2} className="itemRodape">
            <h3 className="tituloRodape">Institucional</h3>
            <div className="textoRodape">
              {menus?.map((menu, i) => (
                <Link title={menu.name} to={menu.link} key={i} >
                  <li>
                    <i className="bi bi-dash" style={{ color: "#00A859", fontSize: '0.7rem' }} ></i> {menu.name}
                  </li>
                </Link>
              ))}
              <a title="PORTAL DO REPRESENTANTE" href="https://realmetais.accionsistemas.com.br/pSeven/login.seam?cid=1" target="blank">
                <li>
                  <i className="bi bi-dash" style={{ color: "#00A859", fontSize: '0.7rem' }} ></i> Portal do Representante
                </li>
              </a>
            </div>
          </Col>

          <Col lg={2} className="itemRodape">
            <h3 className="tituloRodape">Produtos</h3>
            <div className="textoRodape">
              {produtos?.map((menu, i) => (
                <Link title={menu.name} to={menu.link} key={i} reloadDocument>
                  <li>
                    <i className="bi bi-dash" style={{ color: "#00A859", fontSize: '0.7rem' }} ></i> {menu.name}
                  </li>
                </Link>
              ))}
            </div>
          </Col>

          <Col lg={5} className="itemRodape">
            <h3 className="tituloRodape">Contato</h3>
            <div className="textoRodape">
              <li>
                <i className="bi bi-dash" style={{color: "#00A859", fontSize: '0.7rem'}}></i> {dados?.address} -{" "}
                {dados?.neighborhood}, {dados?.number} - {dados?.city} -{" "}
                {dados?.state} - {dados?.cep}
              </li>
              <a title={dados?.phones[1].phone} href={"tel:" + dados?.phones[1].phone}>
                <li>
                  <i className="bi bi-dash" style={{color: "#00A859", fontSize: '0.7rem'}}></i> Telefone:{" "}
                  {dados?.phones[1].phone}
                </li>
              </a>
              <a title="dados?.whatsapp"
                 href={`https://api.whatsapp.com/send/?phone=${dados?.whatsapp.replace(/\D+/g, '')}&text&type=phone_number&app_absent=0`}
                 target="blank">
                <li>
                  <i className="bi bi-dash"
                     style={{color: "#00A859", fontSize: '0.7rem'}}></i> Whatsapp: {dados?.whatsapp}
                </li>
              </a>
              <a title={dados?.phones[0].phone} href={"tel:" + dados?.phones[0].phone}>
                <li>
                  <i className="bi bi-dash" style={{color: "#00A859", fontSize: '0.7rem'}}></i> Recepção:{" "}
                  {dados?.phones[0].phone}
                </li>
              </a>
              <a className="email" title={dados?.email} href={"mailto:" + dados?.email}>
                <li>
                  <i className="bi bi-dash" style={{color: "#00A859", fontSize: '0.7rem'}}></i> E-mail: {dados?.email}
                </li>
              </a>
              <li>
                <i className="bi bi-dash" style={{color: "#00A859", fontSize: '0.7rem'}}></i> MH TORRES LTDA {""}
              </li>
              <li>
                <i className="bi bi-dash" style={{color: "#00A859", fontSize: '0.7rem'}}></i> CNPJ: {dados?.cnpj}
              </li>
            </div>
          </Col>
        </Row>

        <Row className="responsivo">
          <Col lg={12} className="itemRodape">
            <div className="imgRodape">
              <img src={dados?.watermark} alt="logo" className="logo" width="100%" height="100%"/>
            </div>
            <div className="iconesRodape">
              <a href="/" target="blank">
                <i className="bi bi-instagram" style={{ color: "white", fontSize: '1.5rem' }} ></i>
                {/* <Instagram className="iconeRedeSociais" /> */}
              </a>
              <a href="/" target="blank">
                <i className="bi bi-facebook" style={{ color: "white", fontSize: '1.5rem' }} ></i>
                {/* <Facebook className="iconeRedeSociais" /> */}
              </a>
              <a href="/" target="blank">
                {/* <Youtube className="iconeRedeSociais" /> */}
                <i className="bi bi-youtube" style={{ color: "white", fontSize: '1.5rem' }} ></i>
              </a>
            </div>

            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Institucional</Accordion.Header>
                <Accordion.Body>
                  {menus?.map((menu, i) => (
                    <Link to={menu.link} key={i}>
                      <li>
                        <i className="bi bi-dash" style={{ color: "#00A859", fontSize: '0.7rem' }} ></i> {menu.name}                        
                      </li>
                    </Link>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Produtos</Accordion.Header>
                <Accordion.Body>
                  {produtos?.map((menu, i) => (
                    <Link to={menu.link} key={i}>
                      <li>
                        <i className="bi bi-dash" style={{ color: "#00A859", fontSize: '0.7rem'}} ></i> {menu.name}

                      </li>
                    </Link>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Contato</Accordion.Header>
                <Accordion.Body>
                  <li>
                    <i className="bi bi-dash" style={{ color: "#00A859", fontSize: '0.7rem' }} ></i> {dados?.address} -{" "}
                    {dados?.neighborhood}, {dados?.number} - {dados?.city} -{" "}
                    {dados?.state} - {dados?.cep}
                  </li>
                  <li>
                    <i className="bi bi-dash" style={{ color: "#00A859", fontSize: '0.7rem' }} ></i> Telefone:{" "}
                    {dados?.phones[0].phone}
                  </li>
                  <li>
                    <i className="bi bi-dash" style={{ color: "#00A859", fontSize: '0.7rem' }} ></i> Whatsapp: {dados?.whatsapp}
                  </li>
                  <li>
                    <i className="bi bi-dash" style={{ color: "#00A859", fontSize: '0.7rem' }} ></i> E-mail: {dados?.email}
                  </li>
                  <li>
                    <i className="bi bi-dash" style={{ color: "#00A859", fontSize: '0.7rem' }} ></i> Mh Torres Ltda. CNPJ: {dados?.cnpj}
                  </li>
                  <li>
                    <i className="bi bi-dash" style={{ color: "#00A859", fontSize: '0.7rem' }} ></i> MH TORRES LTDA {""}
                   </li>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
