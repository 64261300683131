import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Alert } from "react-bootstrap";
import ReCAPTCHA from 'react-google-recaptcha'
import { ContatoStatus, ContatoType } from "../../../modelos/types/types-contato";
import { gestorReqs } from "../../../servicos/gestor";

import { FuncoesAuxiliares as FA } from '../../../utilidades/funcoes-auxiliares'

import "./sessao-contato.scss";

enum PostStatus {
  ENVIADO = 'ENVIADO',
  ENVIANDO = 'ENVIANDO',
  ERRO = 'ERRO',
  NULL = 'NULL'
}

type PostForm = {
  nome: string
  telefone: string
  email: string
  assunto: string
  descricao: string
  captcha: string
}

type InputStatus = {
  nome?: boolean
  telefone?: boolean
  email?: boolean
  assunto?: boolean
  descricao?: boolean
  captcha?: boolean
}

const resetValores = (): PostForm => {
  return { nome: '', telefone: '', email: '', assunto: '', descricao: '', captcha: '' }
}

export const SessaoContato = () => {
  const [inputStatus, setInputStatus] = useState({} as InputStatus)
  const [valores, setValores] = useState<PostForm>({ nome: '', telefone: '', email: '', assunto: '', descricao: '', captcha: '' })
  const [postStatus, setPostStatus] = useState(PostStatus.NULL)
  const [captchaSiteKey, setCaptchaSiteKey] = useState<string>()

  const [recaptcha, setRecaptcha] = useState<ReCAPTCHA>()

  useEffect(() => {
    gestorReqs.getInformacoes().then(response => {
      setCaptchaSiteKey(response.captcha_site_key)
    })
  }, [])

  const setNome = (e: any) => {
    setInputStatus({ ...inputStatus, nome: undefined })
    setValores({ ...valores, nome: e?.target?.value })
  }

  const setTelefone = (e: any) => {
    setInputStatus({ ...inputStatus, telefone: undefined })
    setValores({ ...valores, telefone: FA.mascararTelefone(e?.target?.value) })
  }

  const setEmail = (e: any) => {
    setInputStatus({ ...inputStatus, email: undefined })
    setValores({ ...valores, email: e?.target?.value })
  }

  const setAssunto = (e: any) => {
    setInputStatus({ ...inputStatus, assunto: undefined })
    setValores({ ...valores, assunto: e?.target?.value })
  }

  const setDescricao = (e: any) => {
    setInputStatus({ ...inputStatus, descricao: undefined })
    setValores({ ...valores, descricao: e?.target?.value })
  }

  const setCaptcha = (token?: string) => {
    setValores({ ...valores, captcha: token ?? '' })
    setInputStatus({ ...inputStatus, captcha: !!token })
  }

  const enviar = () => {
    var estatusAux = {
      nome: valores.nome.length >= 3,
      email: FA.validarEmail(valores.email),
      telefone: FA.validarTelefone(valores.telefone),
      assunto: valores.assunto.length >= 3,
      descricao: valores.descricao.length >= 3,
      captcha: !!inputStatus.captcha
    }

    setInputStatus({ ... estatusAux})

    for (let [, value] of Object.entries(estatusAux))
      if(!value)  return

    setPostStatus(PostStatus.ENVIANDO)

    var post: ContatoType = {
      email: valores.email,
      nome: valores.nome,
      telefone: valores.telefone,
      assunto: valores.assunto,
      mensagem: valores.descricao,
      captcha: valores.captcha,
    }

    gestorReqs.postContato(post).then(response => {
      setPostStatus(response === ContatoStatus.OK ? PostStatus.ENVIADO : PostStatus.ERRO)
      
      setValores({...resetValores()})
      setInputStatus({})

      if(recaptcha) recaptcha.reset()

      setTimeout(() => setPostStatus(PostStatus.NULL), 4500)
    })
  } 

  const setarRecaptcha = (ref: ReCAPTCHA | null) => {
    if(ref){
      setRecaptcha(ref)
    }
  }

  return (
    <Container id='sessaoContato'>
      <Row className='inputBox'>
        <Col xs={12} lg={6} className='box1'>
          <Form.Control
            className="formCampo"
            required
            type="text"
            placeholder="Seu nome"
            value={valores.nome}
            onChange={setNome}
            isValid={inputStatus.nome}
            isInvalid={inputStatus.nome != undefined ? !inputStatus.nome : undefined}
            onBlur={() => setInputStatus({ ...inputStatus, nome: valores.nome.length >= 3 })}
          />
          <Form.Control
            className="formCampo"
            required
            type="text"
            placeholder="Seu telefone"
            value={valores.telefone}
            onChange={setTelefone}
            isInvalid={inputStatus.telefone != undefined ? !inputStatus.telefone : undefined}
            isValid={inputStatus.telefone}
            onBlur={() => setInputStatus({ ...inputStatus, telefone: FA.validarTelefone(valores.telefone) })}
          />
          <Form.Control
            className="formCampo"
            required
            type="email"
            placeholder="Seu email"
            value={valores.email}
            onChange={setEmail}
            isInvalid={inputStatus.email != undefined ? !inputStatus.email : undefined}
            isValid={inputStatus.email}
            onBlur={() => setInputStatus({ ...inputStatus, email: FA.validarEmail(valores.email) })}
          />
          <Form.Control
            className="formCampo"
            required
            type="text"
            placeholder="Assunto"
            value={valores.assunto}
            onChange={setAssunto}
            isValid={inputStatus.assunto}
            isInvalid={inputStatus.assunto != undefined ? !inputStatus.assunto : undefined}
            onBlur={() => setInputStatus({ ...inputStatus, assunto: valores.assunto.length >= 3 })}
          />
        </Col>
        <Col xs={12} lg={6} className='box2'>
          <Form.Control
            as="textarea"
            rows={7}
            placeholder="Descrição"
            value={valores.descricao}
            onChange={setDescricao}
            isValid={inputStatus.descricao}
            isInvalid={inputStatus.descricao != undefined ? !inputStatus.descricao : undefined}
            onBlur={() => setInputStatus({ ...inputStatus, descricao: valores.descricao.length >= 3 })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6} className="mt-3 ReCaptcha">
          {captchaSiteKey && <ReCAPTCHA
            hl="pt"
            ref={el => setarRecaptcha(el)}
            sitekey={captchaSiteKey}
            onChange={(e: any) => { setCaptcha(e); setarRecaptcha(e) }}
            onErrored={() => {
              setCaptcha(undefined)
              setInputStatus({ ...inputStatus, captcha: undefined })
            }}
            onExpired={() => {
              setCaptcha(undefined)
              setInputStatus({ ...inputStatus, captcha: undefined })
            }}
          />}
        </Col>
        <Col xs={12} lg={6} className="mt-3 enviar">
          <Button className="botao" onClick={() => enviar()}>
            Fale conosco
          </Button>
        </Col>
      </Row>
      {inputStatus.captcha == false ?
        <Row>
          <Col lg={12}>
            <Alert className="alert mt-3" variant="secondary">
              Por favor responda o ReCaptcha !
            </Alert>
          </Col>
        </Row> : null}
      {postStatus != PostStatus.NULL ?
        <Row>
          <Col lg={12}>
            <Alert className="alert mt-3" variant={
              postStatus === PostStatus.ENVIADO ? 'success' : postStatus === PostStatus.ENVIANDO ? 'primary' : 'danger'}>
              {postStatus === PostStatus.ENVIADO ? 'Formulário enviado com sucesso !' :
                postStatus === PostStatus.ENVIANDO ? 'Enviando ...' : 'Houve um erro ao enviar o formulário, por favor, tente novamente mais tarde!'}
            </Alert>
          </Col>
        </Row> : null}
    </Container>
  );
};
